import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import bemCnFast from '@webteam/bem-cn-fast';

import { withTheme } from '@webteam/ui-contexts';

import localNames from './formik-trigger.pcss';

const bemCn = bemCnFast('wt-select-wrapper', localNames);

function FormikTriggerWrapper(props) {
  const {
    size,
    theme,
    className,
    error,
    label,
    note,
    children,
    'data-test': dataTest
  } = props;

  return (
    <div
      className={cn(bemCn({ size, theme }), className)}
      data-test={dataTest || 'select-wrapper'}
    >
      {renderLabel(label, size, theme)}
      {children}
      {renderNote(note, size, theme)}
      {renderError(error, size, theme)}
    </div>
  );
}

FormikTriggerWrapper.propTypes = {
  size: PropTypes.oneOf(['m', 's', 'xs']),
  theme: PropTypes.oneOf(['light', 'dark']),
  className: PropTypes.string,
  error: PropTypes.node,
  label: PropTypes.node,
  note: PropTypes.node,
  'data-test': PropTypes.string
};

function renderNote(note, size, theme) {
  if (!note) {
    return null;
  } else {
    return (
      <div className={cn(bemCn('note'), getErrorAndNoteTextClass(size, theme))}>
        {note}
      </div>
    );
  }
}

function renderError(error, size, theme) {
  if (!error) {
    return null;
  } else {
    return (
      <div
        className={cn(
          bemCn('error-message'),
          getErrorAndNoteTextClass(size, theme)
        )}
      >
        {error}
      </div>
    );
  }
}

function getErrorAndNoteTextClass(size, theme) {
  let errorAndNoteTextClass = size === 'l' ? 'wt-text-2' : 'wt-text-3';

  if (theme === 'dark') {
    errorAndNoteTextClass = `${errorAndNoteTextClass} ${errorAndNoteTextClass}_theme_dark`;
  }

  return errorAndNoteTextClass;
}

function renderLabel(label, size, theme) {
  let labelTextClass =
    // eslint-disable-next-line no-nested-ternary
    size === 'xs' ? 'wt-text-3' : size === 'l' ? 'wt-text-1' : 'wt-text-2';

  if (theme === 'dark') {
    labelTextClass = `${labelTextClass} ${labelTextClass}_theme_dark`;
  }

  if (!label) {
    return null;
  } else {
    return <div className={labelTextClass}>{label}</div>;
  }
}

export default withTheme(FormikTriggerWrapper);

// eslint-disable-next-line import/no-extraneous-dependencies
import ReactDOM from 'react-dom';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import throttle from 'just-throttle';
import { ResizeObserver as Polyfill } from '@juggle/resize-observer';

const ResizeObserver =
  (typeof window !== 'undefined' && window.ResizeObserver) || Polyfill;

export default class Foldable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lastVisible: props.children.length - 1,
      renderingStage: 0,
      prevChildren: props.children
    };

    this.containerRef = React.createRef();
    this.plusNRef = React.createRef();
    this.childrenRefs = [];

    this.childrenWidths = [];
  }

  static getDerivedStateFromProps(props, state) {
    if (props.children !== state.prevChildren) {
      return {
        prevChildren: props.children,
        renderingStage: 0
      };
    }

    return null;
  }

  componentDidMount() {
    const { renderingStage } = this.state;
    if (renderingStage === 0) {
      this.measureChildrens();
      this.recalculateLastVisibleAndSetStage(1);
    }

    const onSizeChangedThrottled = throttle(() => {
      this.containerWidth = measureElement(this.containerRef.current).width;
      if (this.state.renderingStage === 2) {
        this.recalculateLastVisibleAndSetStage();
      }
    }, 50);
    this.resizeObserver = new ResizeObserver(onSizeChangedThrottled);
    this.resizeObserver.observe(this.containerRef.current);
  }

  componentDidUpdate() {
    const { renderingStage } = this.state;
    if (renderingStage === 0) {
      this.measureChildrens();
      this.recalculateLastVisibleAndSetStage(1);
    } else if (renderingStage === 1) {
      this.recalculateLastVisibleAndSetStage(2);
    }
  }

  componentWillUnmount() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }

  measureChildrens = () => {
    const { childrenRefs } = this;

    if (childrenRefs.some(r => r.current === null)) {
      throw new Error('childrenRefs should be all not nulls');
    }

    this.childrenWidths = childrenRefs.map(r => measureElement(r).width);
  };

  recalculateLastVisibleAndSetStage = (nextStage = null) => {
    const { containerRef, plusNRef } = this;

    if (!containerRef.current) {
      throw new Error('containerRef should be not null during recaluclate');
    }

    const containerWidth = measureElement(containerRef.current).width;

    let plusNWidth = 0;
    if (plusNRef.current) {
      plusNWidth = measureElement(plusNRef.current).width;
    }

    const gap = this.props.gap || 0;

    const [index, extra] = lastVisibleIndex(
      this.childrenWidths,
      containerWidth,
      gap,
      plusNWidth + gap + 1
    );

    const children = React.Children.toArray(this.props.children);

    const showLastChildIfFit =
      index === children.length - 2 &&
      extra - gap - 1 > this.childrenWidths[this.childrenWidths.length - 1];

    const newLastVisible = index + (showLastChildIfFit ? 1 : 0);

    const newState = {
      lastVisible: newLastVisible
    };

    if (nextStage) {
      newState.renderingStage = nextStage;
    }

    this.setState(newState);
  };

  renderChildren(lastVisible) {
    const children = React.Children.toArray(this.props.children);

    return children.slice(0, lastVisible + 1).map((child, i) =>
      React.cloneElement(child, {
        ref: el => {
          if (el) {
            this.childrenRefs[i] = el;
          }
        }
      })
    );
  }

  render() {
    const { renderHiddenCounter } = this.props;
    const children = React.Children.toArray(this.props.children);

    const lastVisible =
      this.state.renderingStage === 0
        ? children.length - 1
        : this.state.lastVisible;

    if (children.length !== this.childrenRefs.length) {
      this.childrenRefs = this.childrenRefs.slice(0, children.length);
    }

    return (
      <div style={{ width: '100%' }} ref={this.containerRef}>
        {this.renderChildren(lastVisible)}
        {lastVisible !== children.length - 1
          ? renderHiddenCounter(
              children.length - lastVisible - 1,
              this.plusNRef
            )
          : null}
      </div>
    );
  }
}

Foldable.propTypes = {
  gap: PropTypes.number,
  renderHiddenCounter: PropTypes.func
};

function lastVisibleIndex(lens, maxLen, gap, extra = 0) {
  let currentLen = 0;

  for (let i = 0; i < lens.length; ++i) {
    const gapIfNeeded = i === 0 ? 0 : gap;
    if (currentLen + lens[i] + gapIfNeeded > maxLen - extra) {
      return [i - 1, maxLen - currentLen];
    }
    currentLen += lens[i] + gapIfNeeded;
  }

  return [lens.length - 1, maxLen - currentLen];
}

function measureElement(element) {
  // eslint-disable-next-line react/no-find-dom-node
  const domNode = ReactDOM.findDOMNode(element);
  return {
    width: domNode.offsetWidth,
    height: domNode.offsetHeight
  };
}

import React, {
  Component,
  createElement,
  cloneElement,
  isValidElement
} from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { Manager, Reference, Popper } from 'react-popper';
import bemCn from '@webteam/bem-cn-fast';
import { LayeringConsumer } from '@webteam/ui-contexts';

import localNames from './index.pcss';

const HIDE_TIMEOUT = 500;

const Portal = ({ children, target = document.body }) =>
  ReactDOM.createPortal(children, target);

const isNode = maybeNode => {
  switch (typeof maybeNode) {
    case 'string':
    case 'number':
      return true;
    case 'object':
      if (isValidElement(maybeNode)) {
        return true;
      }
      if (Array.isArray(maybeNode)) {
        return maybeNode.every(isNode);
      }
      return false;
    default:
      return false;
  }
};

const cn = bemCn('wt-tooltip', localNames);

export default class Tooltip extends Component {
  static propTypes = {
    /** target node or `({targetProps}) => node` function */
    children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
    /** wrapper component type if children is not a function */
    targetComponent: PropTypes.string,
    /** tooltip content */
    content: PropTypes.node,
    className: PropTypes.string,
    /** "External" state control  */
    isVisible: PropTypes.bool,
    /** Pooper.js [modifiers](https://popper.js.org/popper-documentation.html#Popper.Defaults.modifiers) config  */
    modifiers: PropTypes.object,
    /** Please use only `top-end`, `right`, `left`, `right-start` or `left-start` to be compliant with styleguide  */
    placement: PropTypes.oneOf([
      'auto-start',
      'auto',
      'auto-end',
      'top-start',
      'top',
      'top-end',
      'right-start',
      'right',
      'right-end',
      'bottom-end',
      'bottom',
      'bottom-start',
      'left-end',
      'left',
      'left-start'
    ]),
    /** margin size, depends on target size */
    size: PropTypes.oneOf(['m', 's']),
    /** Disable timeout before hide tooltip after mouse left target,
     * use with cation, it can make impossible to click on links inside tooltip */
    disableHideDelay: PropTypes.bool
  };

  static defaultProps = {
    placement: 'auto',
    targetComponent: 'span',
    size: 'm'
  };

  constructor(props) {
    super(props);
    this.state = {
      isControlled: typeof props.isVisible !== 'undefined',
      isVisible: false
    };
  }

  static getDerivedStateFromProps(nextProps) {
    return { isControlled: typeof nextProps.isVisible !== 'undefined' };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.content !== this.props.content) {
      if (this.schedulePopperUpdate) {
        this.schedulePopperUpdate();
      }
    }
  }

  handleMouseEnterTarget = () => {
    if (this.hideTimeout) {
      clearTimeout(this.hideTimeout);
    }
    this.setState({ isVisible: true });
  };

  handleMouseLeaveTarget = () => {
    if (this.props.disableHideDelay) {
      this.setState({ isVisible: false });
    } else {
      this.hideTimeout = setTimeout(() => {
        this.setState({ isVisible: false });
      }, HIDE_TIMEOUT);
    }
  };

  handleMouseEnterTooltip = () => {
    this.setState({ isTooltipHovered: true });
  };

  handleMouseLeaveTooltip = () => {
    this.setState({ isVisible: false, isTooltipHovered: false });
  };

  renderTarget = ({ ref }) => {
    const { children, targetComponent } = this.props;
    const { isControlled } = this.state;

    const eventListeners = !isControlled
      ? {
          onMouseEnter: this.handleMouseEnterTarget,
          onMouseLeave: this.handleMouseLeaveTarget
        }
      : {};

    const tooltipTargetProps = Object.assign({}, { ref }, eventListeners);

    if (typeof children === 'function') {
      return children({ targetProps: tooltipTargetProps });
    } else if (children) {
      return cloneElement(children, tooltipTargetProps);
    }

    return createElement(targetComponent, tooltipTargetProps, children);
  };

  render() {
    const { props, state } = this;
    const { placement, content, modifiers, className, size } = props;
    const { isControlled, isTooltipHovered } = state;
    const { isVisible } = isControlled ? props : state;

    return (
      <LayeringConsumer>
        {({ tooltipZIndex }) => (
          <Manager tag={false}>
            <Reference>{this.renderTarget}</Reference>
            {(isVisible || isTooltipHovered) && isNode(content) && (
              <Portal>
                <Popper placement={placement} modifiers={modifiers}>
                  {({ ref, style, scheduleUpdate }) => {
                    this.schedulePopperUpdate = scheduleUpdate;
                    return (
                      <span
                        className={`${cn({ size })} ${className || ''}`}
                        ref={ref}
                        style={{ ...style, zIndex: tooltipZIndex }}
                        onMouseLeave={this.handleMouseLeaveTooltip}
                        onMouseEnter={this.handleMouseEnterTooltip}
                        data-placement={placement}
                        data-test="tooltip"
                      >
                        <div className={cn('content')}>{content}</div>
                      </span>
                    );
                  }}
                </Popper>
              </Portal>
            )}
          </Manager>
        )}
      </LayeringConsumer>
    );
  }
}

/* eslint-disable no-nested-ternary */
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import bemCnFast from '@webteam/bem-cn-fast';
import Tag from '@webteam/tag';
import { withTheme } from '@webteam/ui-contexts';

import DownIcon from '@webteam/icons/lib/down';
import CloseIcon from '@webteam/icons/lib/close';
import LoadingIcon from '@webteam/icons/lib/loading';

import { optionType } from '../common-prop-types';

import Foldable from './foldable';
import localNames from './trigger.pcss';

const bemCn = bemCnFast('wt-select', localNames);

const TIMEOUT_BETWEEN_EVENTS = 100; //100ms

// eslint-disable-next-line complexity
function SelectTrigger(props) {
  const {
    size,
    theme,
    className,
    triggerRef,
    isOpen,
    selected = null,
    placeholder,
    isClearable,
    isLoading,
    isDisabled,
    isSingleLine,
    searchInput,
    isSearchable,
    onClearClick,
    onItemClearClick,
    onSearchChange,
    error,
    ...restProps
  } = props;

  const inputRef = useRef(null);
  const [searchFocused, setSearchFocused] = useState(false);
  const [lastMouseDownTimestamp, setLastMouseDownTimestamp] = useState(0);
  const isMulti = selected
    ? Boolean(selected.length && selected.length > 0)
    : false;

  function handleChange(event) {
    const newValue = event.target.value;
    onSearchChange(newValue);
  }

  function handleBlur() {
    if (isSearchable && inputRef.current) {
      setSearchFocused(false);
      inputRef.current.blur();
    }
  }

  function handleFocus(e) {
    if (
      e.timeStamp - lastMouseDownTimestamp > TIMEOUT_BETWEEN_EVENTS &&
      isSearchable &&
      inputRef.current
    ) {
      setSearchFocused(true);
      inputRef.current.focus();
    }
  }

  function handleMouseDown(e) {
    setLastMouseDownTimestamp(e.timeStamp);
  }

  return (
    <div
      tabIndex={isSearchable ? -1 : 0}
      className={cn(
        bemCn({
          size,
          theme,
          isMulti,
          searchFocused,
          open: isOpen,
          disabled: isDisabled,
          error: Boolean(error)
        }),
        className
      )}
      ref={triggerRef}
      data-test="select"
      {...restProps}
    >
      {isMulti
        ? renderMultiselectOptions(props)
        : renderSelectedOption(
            props,
            inputRef,
            handleBlur,
            handleFocus,
            handleMouseDown,
            handleChange
          )}
      <div className={bemCn('icons-wrapper', { size })}>
        {renderClearOrLoading(props)}
        <DownIcon
          size={size}
          className={bemCn('toggle', { open: isOpen })}
          data-test="down-icon"
        />
      </div>
    </div>
  );
}

function renderMultiselectOptions({
  /* eslint-disable react/prop-types */
  isSingleLine,
  selected,
  size,
  isDisabled,
  onItemClearClick
}) {
  const selectedOptions = renderSelectedItems(
    selected,
    size,
    isDisabled,
    onItemClearClick
  );

  return (
    <div className={bemCn('multi')} data-test="multi">
      {isSingleLine
        ? wrapWithFoldable(selectedOptions, size, isDisabled)
        : selectedOptions}
    </div>
  );
}

function wrapWithFoldable(selectedOptions, size, isDisabled) {
  return (
    <Foldable
      renderHiddenCounter={(n, ref) => (
        <Tag
          className={bemCn('tag')}
          key="+"
          ref={ref}
          size={{ m: 's', s: 'xs' }[size]}
          disabled={isDisabled}
        >
          {`+${n}`}
        </Tag>
      )}
      gap={{ m: 5, s: 4 }[size]}
    >
      {selectedOptions}
    </Foldable>
  );
}

function renderSelectedItems(selected, size, isDisabled, onItemClearClick) {
  return selected.map(s => {
    const key = s.value ? s.value : s.label;
    return (
      <Tag
        key={key}
        data-test={`selected-tag-${key}`}
        className={bemCn('tag')}
        isRemovable
        disabled={isDisabled}
        onRemoveClick={() => onItemClearClick(s)}
        size={{ m: 's', s: 'xs' }[size]}
      >
        {s.label}
      </Tag>
    );
  });
}

function isSelectedEmpty(selected) {
  if (Array.isArray(selected)) {
    return selected.length === 0;
  } else {
    return !selected;
  }
}

function renderSelectedOption(
  props,
  inputRef,
  handleBlur,
  handleFocus,
  handleMouseDown,
  handleChange
) {
  /* eslint-disable react/prop-types */
  const {
    selected,
    searchInput = '',
    placeholder = '',
    isSearchable,
    isDisabled,
    size
  } = props;

  return (
    <div className={bemCn('label-wrapper')}>
      <div
        className={bemCn('label', {
          notSelected: isSelectedEmpty(selected)
        })}
      >
        {searchInput === ''
          ? selected && selected.label
            ? selected.label
            : placeholder
          : ''}
        {isSearchable && !isDisabled && (
          <input
            ref={inputRef}
            tabIndex={isSearchable ? 0 : -1}
            onBlur={handleBlur}
            onFocus={handleFocus}
            onMouseDown={handleMouseDown}
            className={bemCn('search-input', { size })}
            autoCapitalize="none"
            autoComplete="off"
            autoCorrect="off"
            spellCheck="false"
            type="text"
            value={searchInput}
            onChange={handleChange}
            data-test="search-input"
          />
        )}
      </div>
    </div>
  );
}

function renderClearOrLoading(props) {
  const {
    isClearable,
    isLoading,
    isDisabled,
    onClearClick,
    selected,
    size
  } = props;
  if (isLoading) {
    return (
      <LoadingIcon
        style={{ pointerEvents: 'none' }}
        size={size}
        className={bemCn('icon', { size })}
        data-test="loading-icon"
      />
    );
  } else if (isClearable && !isDisabled && !isSelectedEmpty(selected)) {
    return (
      <CloseIcon
        size={size}
        className={bemCn('icon', { size })}
        data-test="clear-icon"
        onClick={e => {
          e.stopPropagation();
          onClearClick();
        }}
      />
    );
  } else {
    return null;
  }
}

SelectTrigger.propTypes = {
  size: PropTypes.oneOf(['m', 's', 'xs']),
  theme: PropTypes.oneOf(['light', 'dark']),
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  selected: PropTypes.oneOfType([optionType, PropTypes.arrayOf(optionType)]),
  placeholder: PropTypes.string,
  isClearable: PropTypes.bool,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isSearchable: PropTypes.bool,
  isSingleLine: PropTypes.bool,
  onClearClick: PropTypes.func,
  onItemClearClick: PropTypes.func,
  onSearchChange: PropTypes.func,
  error: PropTypes.bool,
  triggerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.any
    })
  ]),
  searchInput: PropTypes.string
};

export default withTheme(SelectTrigger);

/* eslint-disable import/no-commonjs */
const PREFIX = 'wt';

const GUTTER_HORIZONTAL = {
  l: 20,
  m: 16,
  s: 8,
  xs: 6,
  '0': 0
};

const CSS_VARS = {
  COL_WIDTH: '--wt-col-width',
  COL_COUNT: '--wt-col-count',
  GUTTER_HORIZONTAL: '--wt-horizontal-layout-gutter',
  OFFSET_LEFT: '--wt-offset-left',
  OFFSET_TOP_UNIT: '--wt-offset-top-unit' // Don't forget! A var with same name exist in index.pcss
};

const CONTAINER_BY_BREAKPOINTS = {
  max: {
    description: 'Max size (desktop, laptop). Screen size Max.',
    maxWidth: 1276,
    padding: 22
  },
  lg: {
    description: 'Large (tablet). Screen size less 1276px.',
    maxWidth: 996,
    padding: 22
  },
  md: {
    description: 'Medium (phone). Screen size less 1000px.',
    maxWidth: 996,
    padding: 22
  },
  sm: {
    description: 'Small (phone). Screen size less 640px.',
    maxWidth: 640,
    padding: 16
  }
};

const RESIZABLE_CONTAINER_BY_BREAKPOINTS = {
  max: {
    description: 'Max size (desktop, laptop). Screen size Max.',
    padding: '10%'
  },
  xlg: {
    description: 'Extra large (desktop, laptop). Screen size less 1536px.',
    padding: '6.4%'
  },
  lg: {
    description: 'Large (tablet). Screen size less 1280px.',
    padding: 32
  },
  sm: {
    description: 'Small (phone). Screen size less 640px.',
    padding: 16
  }
};

module.exports = {
  CONTAINER_BY_BREAKPOINTS,
  RESIZABLE_CONTAINER_BY_BREAKPOINTS,
  PREFIX,
  TOTAL_COLS: 12,
  CSS_VARS,

  OFFSET_TOP_UNIT: 24,
  OFFSET_TOP: [0, 8, 12, 16, 24, 32, 48, 64, 96],

  GUTTER_HORIZONTAL,
  GUTTER_HORIZONTAL_KEYS: Object.keys(GUTTER_HORIZONTAL)
};

import React from 'react';
import PropTypes from 'prop-types';
import { CheckboxList } from '@webteam/checkbox';
import { useField, useFormikContext } from 'formik';

const FormikCheckboxList = ({
  name,
  onChange = () => {},
  disabled,
  ...restProps
}) => {
  const [{ value }, meta] = useField({
    name,
    multiple: true
  });
  const {
    setFieldValue,
    setFieldTouched,
    status,
    isSubmitting,
    submitCount
  } = useFormikContext();
  return (
    <CheckboxList
      {...restProps}
      value={value}
      error={(meta.touched || submitCount > 0) && meta.error}
      disabled={
        isSubmitting ||
        (status && (status.disabled || status.submitted)) ||
        disabled
      }
      onChange={val => {
        onChange(val);
        setFieldValue(name, val);
        if (!meta.touched) {
          setFieldTouched(name, true, false);
        }
      }}
    />
  );
};

FormikCheckboxList.propTypes = {
  name: PropTypes.string,
  ...CheckboxList.propTypes
};

export default FormikCheckboxList;

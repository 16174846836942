cursorMovedUp.type = 'STEP_UP';

export function cursorMovedUp() {
  return { type: cursorMovedUp.type };
}

cursorMovedDown.type = 'STEP_DOWN';

export function cursorMovedDown() {
  return { type: cursorMovedDown.type };
}

setCursorIndex.type = 'SET_CURSOR_INDEX';

export function setCursorIndex(cursorIndex) {
  return {
    type: setCursorIndex.type,
    cursorIndex
  };
}

setGroups.type = 'SET_GROUPS';

export function setGroups(groups) {
  return {
    type: setGroups.type,
    groups
  };
}

setSelectedValue.type = 'SET_SELECTED_VALUE';

export function setSelectedValue(selected) {
  return {
    type: setSelectedValue.type,
    selected
  };
}

setSelectedIndex.type = 'SET_SELECTED_BY_INDEX';

export function setSelectedIndex(index) {
  return {
    type: setSelectedIndex.type,
    index
  };
}

addSelectedIndex.type = 'ADD_SELECTED_BY_INDEX';

export function addSelectedIndex(index) {
  return {
    type: addSelectedIndex.type,
    index
  };
}

removeSelectedIndex.type = 'REMOVE_SELECTED_BY_INDEX';

export function removeSelectedIndex(index) {
  return {
    type: removeSelectedIndex.type,
    index
  };
}

clearSelectedAndCloseMenu.type = 'CLEAR_SELECTED_AND_CLOSE_MENU';

export function clearSelectedAndCloseMenu() {
  return { type: clearSelectedAndCloseMenu.type };
}

setSearchString.type = 'SET_SEARCH_STRING';

export function setSearchString(searchString, filterFn = null) {
  return {
    type: setSearchString.type,
    searchString,
    filterFn
  };
}

openMenu.type = 'OPEN_MENU';

export function openMenu() {
  return { type: openMenu.type };
}

closeMenu.type = 'CLOSE_MENU';

export function closeMenu() {
  return { type: closeMenu.type };
}

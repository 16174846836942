import { getOptionValue, getPlainOptionsFromGroups } from '../select/selectors';

export const getGroups = state => {
  const groups =
    state.searchString && state.searchString !== ''
      ? state.filteredGroups
      : state.groups;
  return state.hideSelected ? state.groupsWithoutSelected : groups;
};

export const isEmpty = state => {
  const groups = getGroups(state);
  return groups.length === 0 || groups.every(g => g.options.length === 0);
};

export const getPlainOptions = state =>
  getPlainOptionsFromGroups(getGroups(state));

export const isSelected = (state, value) => {
  if (!state.selected) {
    return false;
  }

  return Boolean(state.selected.find(o => getOptionValue(o) === value));
};

export const getSelectedArray = state => (state.selected ? state.selected : []);

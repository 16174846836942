import { createContext } from 'react';

export default createContext({
  theme: '',
  size: 's',
  heavy: false,
  openClassName: '',
  openCollapseKey: '',
  toggleActiveCollapseKey: () => {},
  setActiveKey: () => {}
});

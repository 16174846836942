import React from 'react';
import PropTypes from 'prop-types';
import { List } from '@webteam/list';

import cn from 'classnames';

import RadioManager from './radio-button-manager';
import { bemCn } from './bem-cn';

const RadioButtonList = ({
  children,
  mode = 'nude',
  disabled,
  size = 'm',
  theme,
  error,
  className,
  // eslint-disable-next-line react/prop-types
  style,
  ...restProps
}) => (
  <RadioManager
    disabled={disabled}
    size={size}
    theme={theme}
    /*error={Boolean(error)}  Don't show error on every radio button */
    {...restProps}
  >
    <div className={className} style={style}>
      {error && typeof error !== 'boolean' && (
        <div className={cn(bemCn('error-message'), 'wt-text-3')}>{error}</div>
      )}
      <List
        disabled={disabled}
        theme={theme}
        size={size}
        tag={'div'}
        mode={mode}
      >
        {children}
      </List>
    </div>
  </RadioManager>
);

RadioButtonList.propTypes = {
  mode: PropTypes.oneOf(['nude', 'cell']),
  /** Initial value for uncontrolled mode */
  defaultValue: PropTypes.string,
  /** Disabled state */
  disabled: PropTypes.bool,
  /** Error flag or message */
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.node]),
  /** Change value callback. `value => ...` */
  onChange: PropTypes.func,
  /** Appearance theme */
  theme: PropTypes.oneOf(['light', 'dark']),
  /** Set value to control state of the component. `onChange` callback is required. */
  value: PropTypes.string,
  size: PropTypes.oneOf(['m', 's', 'xs'])
  //...RadioManager.propTypes
};

export default RadioButtonList;

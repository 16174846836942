import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import bemCnFast from '@webteam/bem-cn-fast';
import { withTheme } from '@webteam/ui-contexts';

import localNames from '../index.pcss';

const bemCn = bemCnFast('wt-checkbox', localNames);

const Mark12 = props => (
  <svg
    height="12"
    viewBox="0 0 12 12"
    width="12"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="m4.64307 10.05371-3.37159-3.58838 1.457-1.36914 1.92631 2.04932 4.62255-4.83643 1.44532 1.38184z" />
  </svg>
);

const Mark16 = props => (
  <svg
    height="16"
    viewBox="0 0 16 16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="m6.306 12.454-4.035-4.294 1.458-1.369 2.588 2.755 5.96-6.237 1.446 1.382z" />
  </svg>
);

const CheckboxControlInner = React.forwardRef(function CheckboxControlInner(
  {
    className,
    children,
    theme,
    error,
    disabled,
    size = 'm',
    indeterminate,
    notUseLabelTag = false,
    ...restProps
  },
  ref
) {
  const Mark = size === 'xs' ? Mark12 : Mark16;
  const RootTag = notUseLabelTag ? 'span' : 'label';

  return (
    <RootTag
      className={cn(
        bemCn({ theme, disabled, size, indeterminate, error }),
        className
      )}
      data-test="checkbox"
    >
      <input
        data-test="checkbox-input"
        {...restProps}
        ref={ref}
        className={bemCn('input')}
        type="checkbox"
        disabled={disabled}
      />
      <span
        className={bemCn('icon', { error: Boolean(error) })}
        data-test={'checkbox-icon'}
      >
        <Mark className={bemCn('checkmark')} data-test="checkmark" />
      </span>
    </RootTag>
  );
});

CheckboxControlInner.propTypes = {
  /** Checked state. `onChange` callback is required. */
  checked: PropTypes.bool,
  /** Is checkbox initially checked */
  defaultChecked: PropTypes.bool,
  /** Input name attribute */
  name: PropTypes.string,
  /** Appearance theme */
  theme: PropTypes.oneOf(['light', 'dark']),
  /** Disabled state */
  disabled: PropTypes.bool,
  /** Error state */
  error: PropTypes.bool,
  /** Value of the checkbox */
  value: PropTypes.string,
  /** Change value callback. `value => ...` */
  onChange: PropTypes.func,
  /** Focus input callback */
  onFocus: PropTypes.func,
  /** Blur input callback */
  onBlur: PropTypes.func,
  /** Indeterminate state */
  indeterminate: PropTypes.bool,
  /** Cancel the use of the `label` tag inside. Set it to `true` only if the component is wrapped in a `label` on the outside. */
  notUseLabelTag: PropTypes.bool,
  size: PropTypes.oneOf(['m', 's', 'xs'])
};

export default withTheme(CheckboxControlInner);

import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import { bemCn } from './utils';

const PopupContent = ({ className, ...restProps }) => (
  <div {...restProps} className={cn(bemCn('content'), className)} />
);

PopupContent.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};

export default PopupContent;

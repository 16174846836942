
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import bemCnFast from 'bem-cn-fast';

import './index.pcss';

const bemCn = bemCnFast('wt-icon');

export const Size = {
  "xs": "xs","s": "s","m": "m","l": "l"
};

const TeamIcon = React.forwardRef(
  ({ theme, size, className, ...props }, ref) => {if (size === Size['xs']) { return (<svg viewBox="0 0 16 16" {...props} className={cn(bemCn({theme, size}), className)} ref={ref}><path d="M12.825 5h-2.65A2.175 2.175 0 0 0 8 7.175V8H3.175A2.175 2.175 0 0 0 1 10.175V15h7v-3h7V7.175A2.175 2.175 0 0 0 12.825 5z" /><circle cx={11} cy={2.5} r={1.5} /><circle cx={5} cy={5} r={1.5} /></svg>); }
return (<svg viewBox="0 0 24 24" {...props} className={cn(bemCn({theme, size}), className)} ref={ref}><path d="M18 7h-3a3 3 0 0 0-3 3v1H6a3 3 0 0 0-3 3v7h9v-4h9v-7a3 3 0 0 0-3-3z" /><circle cx={16} cy={3} r={2} /><circle cx={8} cy={7} r={2} /></svg>);}
);

TeamIcon.propTypes = {
  className: PropTypes.string,
  theme: PropTypes.oneOf(["light", "dark"]),
  size: PropTypes.oneOf(["xs", "s", "m", "l"])
};

TeamIcon.defaultProps = {
  size: "m"
};

export default TeamIcon;
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import LoadingIcon from '@webteam/icons/lib/loading';
import CloseIcon from '@webteam/icons/lib/close';

import bemCnFast from '@webteam/bem-cn-fast';
import cn from 'classnames';
import { withTheme } from '@webteam/ui-contexts';

// Import CSS directly because there is alternative input implementation for use as CSS API
import localNames from './index.pcss';

const bemCn = bemCnFast('wt-input', localNames);

export class Input extends PureComponent {
  static propTypes = {
    id: PropTypes.string,
    /** Set input ClassName */
    className: PropTypes.string,
    /**  */
    placeholder: PropTypes.string,
    /** Disable input */
    disabled: PropTypes.bool,
    /** Error state flag or message */
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.node]),
    /** Set busy state */
    busy: PropTypes.bool,
    /** Change value callback. `changeEvent => ...` */
    onChange: PropTypes.func,
    /** Focus input callback */
    onFocus: PropTypes.func,
    /** Blur input callback */
    onBlur: PropTypes.func,
    /** Clear input callback, clear button appears when this callback provided and input is not empty */
    onClear: PropTypes.func,
    /** Size */
    size: PropTypes.oneOf(['xs', 's', 'm', 'l']),
    /** Appearance theme */
    theme: PropTypes.oneOf(['light', 'dark']),
    /** Change input behavior */
    type: PropTypes.oneOf([
      'text',
      'email',
      'search',
      'date',
      'password',
      'number',
      'file',
      'url',
      'tel'
    ]),
    /** Set value to control state of the input. `onChange` callback is required. */
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.array
    ]),
    icon: PropTypes.node,
    /** If true icon will receive styles for hover and cursor: pointer, working only with icons from `@webteam/icons` package */
    iconIsAction: PropTypes.bool,
    label: PropTypes.node,
    note: PropTypes.node,
    iconPosition: PropTypes.oneOf(['left', 'right']),
    /** clear icon customization, supports only icons from `@webteam/icons` package */
    clearIcon: PropTypes.element,
    suffix: PropTypes.node,

    /** Turns off system microelements in input. For example turns off arrows for input[type='number'] */
    offSystemMicroelements: PropTypes.bool
  };

  static defaultProps = {
    theme: 'light',
    size: 'm',
    type: 'text',
    iconPosition: 'right',
    iconIsAction: false,
    clearIcon: <CloseIcon />
  };

  static getDerivedStateFromProps(props) {
    return { empty: !props.value };
  }

  constructor(props) {
    super(props);
    this.state = { empty: !props.value };
  }

  componentWillUnmount() {
    const { onChange, type } = this.props;
    if (type === 'file' && onChange) {
      onChange({
        target: {
          type: 'file',
          files: []
        }
      });
    }
  }

  handleInputRef = inputRef => {
    this.inputRef = inputRef;
  };

  handleChange = e => {
    const { onChange } = this.props;
    this.setState({ empty: !e.target.value });
    if (onChange) {
      onChange(e);
    }
  };

  focus = () => {
    this.inputRef.focus();
  };

  renderRightIcons = () => {
    const {
      icon,
      busy,
      size,
      theme,
      iconPosition,
      onClear,
      clearIcon,
      iconIsAction
    } = this.props;
    const { empty } = this.state;

    const icons = [];

    if (onClear && !empty) {
      icons.push(
        React.cloneElement(clearIcon, {
          key: 'clear',
          size,
          theme,
          className: cn(
            clearIcon.props.className,
            bemCn('icon', { right: true, action: true })
          ),
          onClick: onClear
        })
      );
    }

    if (icon && iconPosition === 'right') {
      icons.push(
        React.cloneElement(icon, {
          key: 'icon',
          size,
          theme,
          className: cn(
            icon.props.className,
            bemCn('icon', { right: true, action: iconIsAction })
          )
        })
      );
    }

    if (busy) {
      icons[icons.length > 0 ? icons.length - 1 : 0] = (
        <LoadingIcon
          key={'loading'}
          size={size}
          className={bemCn('icon', { right: true })}
          theme={theme}
        />
      );
    }

    return <>{icons}</>;
  };

  renderLeftIcon = () => {
    const { icon, iconPosition, size, theme, iconIsAction } = this.props;

    if (icon && iconPosition === 'left') {
      return React.cloneElement(icon, {
        size,
        theme,
        className: cn(
          icon.props.className,
          bemCn('icon', { left: true, action: iconIsAction })
        )
      });
    }

    return null;
  };

  // eslint-disable-next-line complexity
  render() {
    const {
      id,
      className,
      disabled,
      error,
      type,
      theme,
      size,
      icon,
      busy,
      label,
      note,
      iconPosition,
      onClear,
      iconIsAction,
      // eslint-disable-next-line react/prop-types
      style,
      suffix,
      clearIcon,
      offSystemMicroelements,
      ...restProps
    } = this.props;
    const { empty } = this.state;

    if (type === 'file' && restProps.value !== null) {
      delete restProps.value;
    }

    let labelTextClass =
      // eslint-disable-next-line no-nested-ternary
      size === 'xs' ? 'wt-text-3' : size === 'l' ? 'wt-text-1' : 'wt-text-2';

    let errorAndNoteTextClass = size === 'l' ? 'wt-text-2' : 'wt-text-3';

    if (theme === 'dark') {
      errorAndNoteTextClass = `${errorAndNoteTextClass} ${errorAndNoteTextClass}_theme_dark`;
      labelTextClass = `${labelTextClass} ${labelTextClass}_theme_dark`;
    }

    const hasIcon = !!icon || busy;
    return (
      <label
        className={cn(
          bemCn({
            theme,
            size,
            error: Boolean(error),
            empty,
            icon: hasIcon,
            'icon-position': iconPosition,
            disabled,
            'with-suffix': Boolean(suffix),
            'off-system-microelements': offSystemMicroelements
          }),
          className
        )}
        style={style}
        data-test={`input${error ? ' input-with-error' : ''}`}
      >
        {label && <div className={labelTextClass}>{label}</div>}
        <div className={bemCn('wrapper')}>
          <div className={bemCn('field')}>
            {this.renderLeftIcon()}
            <input
              {...restProps}
              id={id}
              className={cn(bemCn('inner'))}
              type={type}
              disabled={disabled}
              onChange={this.handleChange}
              ref={this.handleInputRef}
              aria-invalid={Boolean(error)}
            />
            {this.renderRightIcons()}
          </div>
          {suffix && <div className={bemCn('suffix')}>{suffix}</div>}
        </div>
        {note && (
          <div className={cn(bemCn('note'), errorAndNoteTextClass)}>{note}</div>
        )}
        {error && typeof error !== 'boolean' && (
          <div
            className={cn(bemCn('error-message'), errorAndNoteTextClass)}
            data-test="error-message"
          >
            {error}
          </div>
        )}
      </label>
    );
  }
}

export default withTheme(Input);

import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import { bemCn } from './utils';

const PopupFooter = ({ className, ...restProps }) => (
  <div
    {...restProps}
    className={cn(bemCn('footer'), className)}
    data-test="popup-footer"
  />
);

PopupFooter.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};

export default PopupFooter;

import React, { useContext } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import bemCnFast from '@webteam/bem-cn-fast';
import { withTheme } from '@webteam/ui-contexts';

import localNames from '../index.pcss';

import ListContextInner from './list-context-inner';

const bemCn = bemCnFast('wt-list-group-header', localNames);

function GroupHeader({
  className = null,
  text,
  size: propsSize = 'm',
  theme: propsTheme
}) {
  const { size, theme } = useContext(ListContextInner);

  return (
    <li
      className={cn(
        bemCn({
          size: size || propsSize,
          theme: theme || propsTheme
        }),
        className
      )}
    >
      {text}
    </li>
  );
}

GroupHeader.propTypes = {
  size: PropTypes.oneOf(['m', 's', 'xs']),
  className: PropTypes.string,
  theme: PropTypes.oneOf(['light', 'dark']),
  text: PropTypes.string
};

export default withTheme(GroupHeader);

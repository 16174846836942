import bemCnFast from 'bem-cn-fast';
import { useTheme } from '@webteam/ui-contexts';
import './styles';

export const useTextStyles = () => {
  const theme = useTheme();

  return (
    type = 'wt-text-1',
    params = { flow: false, hardness: false, external: false }
  ) =>
    bemCnFast(type)({
      flow: params.flow,
      hardness: params.hardness,
      external: params.external,
      theme
    });
};

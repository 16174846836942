
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import bemCnFast from 'bem-cn-fast';

import './index.pcss';

const bemCn = bemCnFast('wt-icon');

export const Size = {
  "xs": "xs","s": "s","m": "m","l": "l"
};

const DateIcon = React.forwardRef(
  ({ theme, size, className, ...props }, ref) => {if (size === Size['xs']) { return (<svg viewBox="0 0 16 16" {...props} className={cn(bemCn({theme, size}), className)} ref={ref}><path d="M13.444 4.317V2.765a.778.778 0 0 0-1.555 0v1.552H4.11V2.765a.778.778 0 0 0-1.555 0v1.552H1v8.389A2.314 2.314 0 0 0 3.333 15h9.334a2.332 2.332 0 0 0 2.2-1.53 2.257 2.257 0 0 0 .133-.764V4.317zm0 8.389a.771.771 0 0 1-.777.765h-9.31c-.43 0-.801 0-.801-.765V8.882h10.888zm0-5.353H2.556v-1.53h10.888z" /></svg>); }
return (<svg viewBox="0 0 24 24" {...props} className={cn(bemCn({theme, size}), className)} ref={ref}><path d="M19 6.03V4a1 1 0 0 0-2 0v2.03H7V4a1 1 0 0 0-2 0v2.03H3V17a3 3 0 0 0 3 3h12a2.997 2.997 0 0 0 3-3V6.03zM19 17a1 1 0 0 1-1 1H6.03C5.478 18 5 18 5 17v-5h14zm0-7H5V8h14z" /></svg>);}
);

DateIcon.propTypes = {
  className: PropTypes.string,
  theme: PropTypes.oneOf(["light", "dark"]),
  size: PropTypes.oneOf(["xs", "s", "m", "l"])
};

DateIcon.defaultProps = {
  size: "m"
};

export default DateIcon;
import React, { createContext, useContext } from 'react';

export const ThemeContext = createContext('light');

export const ThemeConsumer = ThemeContext.Consumer;

export const useTheme = overwriteValue => {
  const themeFromContext = useContext(ThemeContext);
  return overwriteValue || themeFromContext;
};

/**
 * @deprecated use `useTheme` instead
 * */
export const withTheme = WrappedComponent => {
  const ComponentWithTheme = React.forwardRef(
    // eslint-disable-next-line react/prop-types
    ({ theme, ...restProps }, ref) => (
      <ThemeConsumer>
        {themeFromContext => (
          <WrappedComponent
            ref={ref}
            theme={theme || themeFromContext}
            {...restProps}
          />
        )}
      </ThemeConsumer>
    )
  );

  ComponentWithTheme.displayName = `WithTheme(${WrappedComponent.displayName ||
    WrappedComponent.name})`;

  return ComponentWithTheme;
};


import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import bemCnFast from 'bem-cn-fast';

import './index.pcss';

const bemCn = bemCnFast('wt-icon');

export const Size = {
  "xs": "xs","s": "s","m": "m","l": "l"
};

const GearIcon = React.forwardRef(
  ({ theme, size, className, ...props }, ref) => {if (size === Size['xs']) { return (<svg viewBox="0 0 16 16" {...props} className={cn(bemCn({theme, size}), className)} ref={ref}><path d="M11.375 8A3.375 3.375 0 1 0 8 11.375 3.375 3.375 0 0 0 11.375 8zM8 14.848l-6-3.424V4.576l6-3.424 6 3.424v6.848z" /></svg>); }
return (<svg viewBox="0 0 24 24" {...props} className={cn(bemCn({theme, size}), className)} ref={ref}><path d="M16.5 12a4.5 4.5 0 1 0-4.5 4.5 4.5 4.5 0 0 0 4.5-4.5zM12 21.13l-8-4.565v-9.13l8-4.565 8 4.565v9.13z" /></svg>);}
);

GearIcon.propTypes = {
  className: PropTypes.string,
  theme: PropTypes.oneOf(["light", "dark"]),
  size: PropTypes.oneOf(["xs", "s", "m", "l"])
};

GearIcon.defaultProps = {
  size: "m"
};

export default GearIcon;
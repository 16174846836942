
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import bemCnFast from 'bem-cn-fast';

import './index.pcss';

const bemCn = bemCnFast('wt-icon');

export const Size = {
  "xs": "xs","s": "s","m": "m","l": "l"
};

const FileIcon = React.forwardRef(
  ({ theme, size, className, ...props }, ref) => {if (size === Size['xs']) { return (<svg viewBox="0 0 16 16" {...props} className={cn(bemCn({theme, size}), className)} ref={ref}><path d="M8.693 2.5L12.5 6.07v6.723a.708.708 0 0 1-.707.707H5.207a.708.708 0 0 1-.707-.707V2.5h4.193M9.286 1H3v11.793A2.207 2.207 0 0 0 5.207 15h6.586A2.207 2.207 0 0 0 14 12.793V5.421L9.286 1z" /><path d="M13 7H8V2h1.57L13 5.306V7z" /></svg>); }
return (<svg viewBox="0 0 24 24" {...props} className={cn(bemCn({theme, size}), className)} ref={ref}><path d="M13 3H5v16.057A2.943 2.943 0 0 0 7.943 22h8.114A2.943 2.943 0 0 0 19 19.057V9zm3.005 17h-8.01A.995.995 0 0 1 7 19.006V5h5v5h5v9.006a.995.995 0 0 1-.995.994z" /></svg>);}
);

FileIcon.propTypes = {
  className: PropTypes.string,
  theme: PropTypes.oneOf(["light", "dark"]),
  size: PropTypes.oneOf(["xs", "s", "m", "l"])
};

FileIcon.defaultProps = {
  size: "m"
};

export default FileIcon;
import React, { cloneElement, forwardRef } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import bemCnFast from '@webteam/bem-cn-fast';
import { withTheme } from '@webteam/ui-contexts';

import localNames from '../index.pcss';

import ListContextInner from './list-context-inner';

const bemCn = bemCnFast('wt-list-item', localNames);

export const ListItem = forwardRef(function ListItem(
  {
    className,
    tag: propsTag,
    children,
    icon,
    onClick = () => {},
    size: propsSize = 'm',
    mode: propsMode = 'cell',
    theme: propsTheme,
    disabled: disabledFromProps,
    selected: selectedFromProps,
    /* @private */
    highlighted,
    iconPlacement: propsIconPlacement = 'left',
    ...restProps
  },
  ref
) {
  return (
    <ListContextInner.Consumer>
      {/* eslint-disable-next-line complexity */}
      {({
        iconPlacement: contextIconPlacement,
        disabled,
        size,
        mode,
        theme,
        itemTag,
        onItemClick = () => {},
        value
      }) => {
        const Tag = propsTag || itemTag;
        const iconPlacement =
          contextIconPlacement || propsIconPlacement || 'left';
        return (
          <Tag
            ref={ref}
            className={cn(
              bemCn({
                placement: iconPlacement,
                disabled: disabled || disabledFromProps,
                mode: mode || propsMode,
                size: size || propsSize,
                theme: theme || propsTheme,
                selected: selectedFromProps,
                highlighted
              }),
              className
            )}
            onClick={e => {
              onClick(e);
              onItemClick(value, e);
            }}
            data-test={'list-item'}
            {...restProps}
          >
            {iconPlacement === 'left' &&
              icon &&
              cloneElement(icon, {
                className: cn(
                  icon.props.className,
                  bemCn('icon', { placement: iconPlacement })
                )
              })}
            <span className={bemCn('content')}>{children}</span>
            {iconPlacement === 'right' &&
              icon &&
              cloneElement(icon, {
                className: cn(
                  icon.props.className,
                  bemCn('icon', { placement: iconPlacement })
                )
              })}
          </Tag>
        );
      }}
    </ListContextInner.Consumer>
  );
});

ListItem.propTypes = {
  size: PropTypes.oneOf(['l', 'm', 's', 'xs']),
  mode: PropTypes.oneOf(['nude', 'cell']),
  theme: PropTypes.oneOf(['light', 'dark']),
  /** @ignore */
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
  highlighted: PropTypes.bool,
  icon: PropTypes.node,
  iconPlacement: PropTypes.oneOf(['left', 'right']),
  tag: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType])
};

export default withTheme(ListItem);

import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import bemCnFast from '@webteam/bem-cn-fast';
import { withTheme } from '@webteam/ui-contexts';

import localNames from '../index.pcss';

const bemCn = bemCnFast('wt-list-divider', localNames);

function Divider({ className = null, theme, ...restProps }) {
  return <hr className={cn(bemCn({ theme }), className)} {...restProps} />;
}

Divider.propTypes = {
  className: PropTypes.string,
  theme: PropTypes.oneOf(['light', 'dark'])
};

export default withTheme(Divider);

import React, { createContext, useContext } from 'react';

const LAYER_STEP = 500;

export class LayeringContextValue {
  constructor(baseZIndex, isInPortal) {
    Object.defineProperty(this, 'baseZIndex', {
      writable: false,
      enumerable: true,
      value: baseZIndex
    });

    Object.defineProperty(this, 'isInPortal', {
      writable: false,
      enumerable: true,
      value: isInPortal
    });

    Object.defineProperty(this, 'popupZIndex', {
      enumerable: true,
      get: () => this.baseZIndex + 3 * LAYER_STEP
    });

    Object.defineProperty(this, 'dropdownZIndex', {
      enumerable: true,
      get: () => this.baseZIndex + 2 * LAYER_STEP
    });

    Object.defineProperty(this, 'tooltipZIndex', {
      enumerable: true,
      get: () => this.baseZIndex + LAYER_STEP
    });
  }
}

export const LayeringContext = createContext(new LayeringContextValue(0));

export const useLayering = () => useContext(LayeringContext);

export const LayeringConsumer = LayeringContext.Consumer;

/**
 * @deprecated Use `useLayering` instead
 * @param WrappedComponent
 * @return WrappedComponent
 */
export const withLayering = WrappedComponent => props => (
  <LayeringContext.Consumer>
    {layering => <WrappedComponent {...layering} {...props} />}
  </LayeringContext.Consumer>
);

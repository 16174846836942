import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactResizeDetector from 'react-resize-detector';
import cn from 'classnames';
import bemCnFast from '@webteam/bem-cn-fast';
import { withTheme } from '@webteam/ui-contexts';

import localNames from './index.pcss';

const bemCn = bemCnFast('wt-switcher', localNames);

const findIndexByValue = (options, value) => {
  const index = options.findIndex(option => option.value === value);
  return index === -1 ? 0 : index;
};

class Switcher extends Component {
  static propTypes = {
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.node,
        value: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.symbol,
          PropTypes.bool
        ])
      })
    ).isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.symbol,
      PropTypes.bool
    ]),
    defaultValue: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.symbol,
      PropTypes.bool
    ]),
    onChange: PropTypes.func,
    className: PropTypes.string,
    size: PropTypes.oneOf(['m', 's', 'xs']),
    theme: PropTypes.oneOf(['light', 'dark']),
    disabled: PropTypes.bool
  };

  static defaultProps = {
    options: [],
    size: 'm',
    theme: 'light'
  };

  constructor(props) {
    super(props);
    this.optionsRefs = [];
    this.state = {
      optionsOffsetsAndWidth: [],
      selectedIndex: findIndexByValue(props.options, props.defaultValue)
    };
  }

  static getDerivedStateFromProps({ value, options }, { selectedIndex }) {
    const isUncontrolled = typeof value === 'undefined';
    return {
      isUncontrolled,
      selectedIndex: isUncontrolled
        ? selectedIndex
        : findIndexByValue(options, value)
    };
  }

  componentDidMount() {
    this.updateOptionsWidth();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.options !== this.props.options) {
      this.updateOptionsWidth();
    }
  }

  updateOptionsWidth = () => {
    // .filter(Boolean) added for cases when options list reduces during component lifecycle
    this.setState({
      optionsOffsetsAndWidth: this.optionsRefs.filter(Boolean).map(ref => ({
        left: ref.offsetLeft,
        width: ref.getBoundingClientRect().width
      }))
    });
  };

  handleOptionClick = (index, value) => {
    const { isUncontrolled } = this.state;
    const { onChange } = this.props;
    if (isUncontrolled) {
      this.setState({ selectedIndex: index });
    }
    if (onChange) {
      onChange(value, index);
    }
  };

  handleOptionRef = (index, ref) => (this.optionsRefs[index] = ref);

  renderOption = ({ value, label }, index) => {
    const { theme, size, disabled } = this.props;
    const textBem = bemCnFast(size === 'xs' ? 'wt-text-3' : 'wt-text-2');
    /* eslint-disable react/jsx-no-bind */
    return (
      <button
        key={value}
        value={value}
        ref={ref => this.handleOptionRef(index, ref)}
        onClick={() => this.handleOptionClick(index, value)}
        type="button"
        disabled={disabled}
        className={cn(
          bemCn('option', { selected: this.state.selectedIndex === index }),
          textBem({ theme })
        )}
      >
        {label}
      </button>
    );
    /* eslint-enable react/jsx-no-bind */
  };

  getMarkStyle = () => {
    const { selectedIndex, optionsOffsetsAndWidth } = this.state;
    return (
      optionsOffsetsAndWidth[selectedIndex] || {
        width: 0,
        left: 0
      }
    );
  };

  render() {
    const { options, size, className, theme, disabled } = this.props;
    return (
      <span className={cn(bemCn({ theme, size, disabled }), className)}>
        {options.map(this.renderOption)}
        <span className={bemCn('mark')} style={this.getMarkStyle()} />
        <ReactResizeDetector handleWidth onResize={this.updateOptionsWidth} />
      </span>
    );
  }
}

export default withTheme(Switcher);


import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import bemCnFast from 'bem-cn-fast';

import './index.pcss';

const bemCn = bemCnFast('wt-icon');

export const Size = {
  "xs": "xs","s": "s","m": "m","l": "l"
};

const InfoIcon = React.forwardRef(
  ({ theme, size, className, ...props }, ref) => {if (size === Size['xs']) { return (<svg viewBox="0 0 16 16" {...props} className={cn(bemCn({theme, size}), className)} ref={ref}><path d="M15 8a7 7 0 1 1-7-7 7 7 0 0 1 7 7zM6.833 4.5A1.167 1.167 0 1 0 8 3.333 1.167 1.167 0 0 0 6.833 4.5zm-.389 2.753v.778h.778v4.636h1.556V8a.747.747 0 0 0-.747-.747z" /></svg>); }
return (<svg viewBox="0 0 24 24" {...props} className={cn(bemCn({theme, size}), className)} ref={ref}><path d="M21 12a9 9 0 1 1-9-9 9 9 0 0 1 9 9zM10.5 7.5A1.5 1.5 0 1 0 12 6a1.5 1.5 0 0 0-1.5 1.5zm-.5 3.54v1h1V18h2v-6a.96.96 0 0 0-.96-.96z" /></svg>);}
);

InfoIcon.propTypes = {
  className: PropTypes.string,
  theme: PropTypes.oneOf(["light", "dark"]),
  size: PropTypes.oneOf(["xs", "s", "m", "l"])
};

InfoIcon.defaultProps = {
  size: "m"
};

export default InfoIcon;
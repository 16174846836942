import React from 'react';
import PropTypes from 'prop-types';
import { useField, useFormikContext } from 'formik';

import { Select } from '@webteam/select';

const getSelectedOption = (options, value, getValue) => {
  if (Array.isArray(options)) {
    return options.find(option => getValue(option) === value) || null;
  }
  return null;
};

const getValue = ({ value }) => value;

const FormikSelect = ({
  name,
  onChange = () => {},
  disabled,
  options,
  ...restProps
}) => {
  const [{ onChange: och, value, ...formikProps }, meta] = useField(name);
  const {
    setFieldValue,
    setFieldTouched,
    isSubmitting,
    status,
    submitCount
  } = useFormikContext();
  return (
    <Select
      {...restProps}
      {...formikProps}
      options={options}
      value={getSelectedOption(options, value, getValue)}
      error={(meta.touched || submitCount > 0) && meta.error}
      isDisabled={
        isSubmitting ||
        (status && (status.disabled || status.submitted)) ||
        disabled
      }
      onChange={option => {
        const val = getValue(option);
        onChange(val);
        setFieldValue(name, val);
        if (!meta.touched) {
          setFieldTouched(name, true, false);
        }
      }}
    />
  );
};

FormikSelect.propTypes = {
  name: PropTypes.string,
  ...Select.propTypes
};

export default FormikSelect;

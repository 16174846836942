
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import bemCnFast from 'bem-cn-fast';

import './index.pcss';

const bemCn = bemCnFast('wt-icon');

export const Size = {
  "xs": "xs","s": "s","m": "m","l": "l"
};

const UndoIcon = React.forwardRef(
  ({ theme, size, className, ...props }, ref) => {if (size === Size['xs']) { return (<svg viewBox="0 0 16 16" {...props} className={cn(bemCn({theme, size}), className)} ref={ref}><path d="M11.61 9.08a3.09 3.09 0 00-3.12-2.71H7.13v3.74L1.88 5.63l5.25-4.51v3.76h1.34a4.6 4.6 0 014.62 3.91 4.51 4.51 0 01-4.46 5.1h-4.5v-1.5h4.5a3 3 0 002.98-3.31z" /></svg>); }
return (<svg viewBox="0 0 24 24" {...props} className={cn(bemCn({theme, size}), className)} ref={ref}><path d="M17 13.6a4.12 4.12 0 00-4.18-3.6H11v5L4 9l7-6v5h1.79A6.15 6.15 0 0119 13.21 6 6 0 0113 20H7v-2h6a4 4 0 004-4.4z" /></svg>);}
);

UndoIcon.propTypes = {
  className: PropTypes.string,
  theme: PropTypes.oneOf(["light", "dark"]),
  size: PropTypes.oneOf(["xs", "s", "m", "l"])
};

UndoIcon.defaultProps = {
  size: "m"
};

export default UndoIcon;

import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import bemCnFast from 'bem-cn-fast';

import './index.pcss';

const bemCn = bemCnFast('wt-icon');

export const Size = {
  "xs": "xs","s": "s","m": "m","l": "l"
};

const PenIcon = React.forwardRef(
  ({ theme, size, className, ...props }, ref) => {if (size === Size['xs']) { return (<svg viewBox="0 0 16 16" {...props} className={cn(bemCn({theme, size}), className)} ref={ref}><path d="M8.751 4.38l-7.024 7.016L1 14.994l3.599-.727 7.019-7.02L8.751 4.38zm5.744-1.01l-1.82-1.88a.718.718 0 0 0-1.023-.009L9.766 3.365l2.866 2.866 1.855-1.855a.718.718 0 0 0 .008-1.007z" /></svg>); }
return (<svg viewBox="0 0 24 24" {...props} className={cn(bemCn({theme, size}), className)} ref={ref}><path d="M13.798 6.212l-9.785 9.775L3 21l5.013-1.013 9.779-9.781-3.994-3.994zm8.003-1.408l-2.535-2.618a1 1 0 0 0-1.425-.012l-2.628 2.625 3.993 3.993 2.584-2.585a1 1 0 0 0 .011-1.403z" /></svg>);}
);

PenIcon.propTypes = {
  className: PropTypes.string,
  theme: PropTypes.oneOf(["light", "dark"]),
  size: PropTypes.oneOf(["xs", "s", "m", "l"])
};

PenIcon.defaultProps = {
  size: "m"
};

export default PenIcon;
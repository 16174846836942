import React from 'react';
import PropTypes from 'prop-types';

import ListContext from './parts/list-context';

const List = ({
  tag: Tag = 'ul',
  itemTag = 'li',
  iconPlacement = 'left',
  disabled,
  size,
  mode,
  theme,
  onItemClick,
  ...restProps
}) => (
  <ListContext
    itemTag={itemTag}
    iconPlacement={iconPlacement}
    disabled={disabled}
    size={size}
    mode={mode}
    theme={theme}
    onItemClick={onItemClick}
  >
    <Tag {...restProps} />
  </ListContext>
);

List.propTypes = {
  size: PropTypes.oneOf(['l', 'm', 's', 'xs']),
  mode: PropTypes.oneOf(['nude', 'cell']),
  theme: PropTypes.oneOf(['light', 'dark']),
  disabled: PropTypes.bool,
  iconPlacement: PropTypes.oneOf(['left', 'right']),
  itemTag: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
  tag: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
  onItemClick: PropTypes.func
};

export default List;

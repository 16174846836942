import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import bemCnFast from '@webteam/bem-cn-fast';
import { withTheme } from '@webteam/ui-contexts';

import { ListItem } from '@webteam/list';

import localNames from './index.pcss';

const bemCn = bemCnFast('wt-toggle', localNames);

export const Toggle = React.forwardRef(function Toggle(
  {
    className,
    checked,
    defaultChecked,
    onChange,
    size,
    theme,
    value,
    children,
    labelPlacement = 'right',
    ...restProps
  },
  ref
) {
  const toggleClassName = children
    ? bemCn({ size, theme })
    : cn(bemCn({ size, theme }), className);

  const toggle = (
    <label className={toggleClassName}>
      <input
        type="checkbox"
        ref={ref}
        defaultChecked={defaultChecked}
        onChange={onChange}
        value={value}
        checked={checked}
        {...restProps}
      />
      <span className={bemCn('slider')} />
    </label>
  );

  if (children) {
    const iconPlacement = labelPlacement === 'right' ? 'left' : 'right';
    const inlineBlockIfNeededClass =
      labelPlacement === 'left' ? bemCn('wrapper') : null;

    return (
      <ListItem
        className={cn(inlineBlockIfNeededClass, className)}
        tag={'label'}
        mode={'nude'}
        size={size}
        theme={theme}
        disabled={false}
        icon={toggle}
        iconPlacement={iconPlacement}
        data-test={'toggle'}
      >
        {children}
      </ListItem>
    );
  } else {
    return toggle;
  }
});

Toggle.defaultProps = {
  size: 'm',
  theme: 'light'
};

Toggle.propTypes = {
  /** Node or text to show near the input */
  children: PropTypes.node,
  /** Label placement relative to toggle */
  labelPlacement: PropTypes.oneOf(['left', 'right']),
  /** Checked state. `onChange` callback is required. */
  checked: PropTypes.bool,
  /** Is toggle initially checked */
  defaultChecked: PropTypes.bool,
  /** Input name attribute */
  name: PropTypes.string,
  /** Change value callback. `value => ...` */
  onChange: PropTypes.func,
  size: PropTypes.oneOf(['xs', 's', 'm']),
  theme: PropTypes.oneOf(['dark', 'light']),
  /** Value of the checkbox */
  value: PropTypes.string
};

export default withTheme(Toggle);

import React from 'react';
import PropTypes from 'prop-types';
import { ListItem } from '@webteam/list';

import RadioControl from './parts/radio-button-control';

const RadioButton = React.forwardRef(function RadioButton(
  { children, className, disabled, ...restProps },
  ref
) {
  return (
    <ListItem
      tag={'label'}
      disabled={disabled}
      className={className}
      icon={
        <RadioControl
          ref={ref}
          disabled={disabled}
          notUseLabelTag
          {...restProps}
        />
      }
    >
      {children}
    </ListItem>
  );
});

RadioButton.propTypes = {
  /** Checked state. `onChange` callback is required. */
  checked: PropTypes.bool,
  /** Node or text to show near the input */
  children: PropTypes.node,
  /** A radio group is defined by giving each of radio buttons in the group the same name */
  theme: PropTypes.oneOf(['light', 'dark']),
  /** Disabled state */
  disabled: PropTypes.bool,
  /** Value used to identify which radio button in a group is selected. The value is never shown to the user by their user agent. */
  value: PropTypes.string.isRequired
};

export default RadioButton;


import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import bemCnFast from 'bem-cn-fast';

import './index.pcss';

const bemCn = bemCnFast('wt-icon');

export const Size = {
  "xs": "xs","s": "s","m": "m","l": "l"
};

const DoubleCheckIcon = React.forwardRef(
  ({ theme, size, className, ...props }, ref) => {if (size === Size['xs']) { return (<svg viewBox="0 0 16 16" {...props} className={cn(bemCn({theme, size}), className)} ref={ref}><path d="m5.1007 14.04582-4.26647-4.26647.9853-.98531 3.28117 3.28117 8.914-8.914.9853.98531zm9.91151-4.24839-.98533-.99238-3.2592 3.27016-1.198-1.198-.98531.9853 2.18333 2.18333z" /></svg>); }
return (<svg viewBox="0 0 24 24" {...props} className={cn(bemCn({theme, size}), className)} ref={ref}><path d="m8.416 20.41406-6.123-6.12306 1.414-1.414 4.709 4.709 12.793-12.793 1.41405 1.414zm14.22-6.08381-1.411-1.42111-4.66727 4.683-1.71561-1.71561-1.411 1.411 3.12659 3.12659z" /></svg>);}
);

DoubleCheckIcon.propTypes = {
  className: PropTypes.string,
  theme: PropTypes.oneOf(["light", "dark"]),
  size: PropTypes.oneOf(["xs", "s", "m", "l"])
};

DoubleCheckIcon.defaultProps = {
  size: "m"
};

export default DoubleCheckIcon;
import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import { bemCn } from './utils';
import { PopupClose } from './close';

const PopupHeader = ({ className, children, ...restProps }) => (
  <div
    {...restProps}
    className={cn(bemCn('header'), 'wt-h3', className)}
    data-test="popup-header"
  >
    <PopupClose className={bemCn('close_header')} />
    {children}
  </div>
);

PopupHeader.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};

export default PopupHeader;

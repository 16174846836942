
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import bemCnFast from 'bem-cn-fast';

import './index.pcss';

const bemCn = bemCnFast('wt-icon');

export const Size = {
  "xs": "xs","s": "s","m": "m","l": "l"
};

const PackageIcon = React.forwardRef(
  ({ theme, size, className, ...props }, ref) => {if (size === Size['xs']) { return (<svg viewBox="0 0 16 16" {...props} className={cn(bemCn({theme, size}), className)} ref={ref}><path d="m2.4 6.4v6.2a1.36707 1.36707 0 0 0 1.4 1.4h8.2a1.36707 1.36707 0 0 0 1.4-1.4v-6.2zm6.9 3.5h-2.7a.68354.68354 0 0 1 -.7-.7.68354.68354 0 0 1 .7-.7h2.8a.68354.68354 0 0 1 .7.7.84854.84854 0 0 1 -.8.7zm4.2-8.3-11.7 2.8.3 1.3 11.7-2.7z" /></svg>); }
return (<svg viewBox="0 0 24 24" {...props} className={cn(bemCn({theme, size}), className)} ref={ref}><path d="m4 10v9a2.00588 2.00588 0 0 0 2 2h12a2.11226 2.11226 0 0 0 2.1-2.1v-8.9zm10 5h-4a1.00209 1.00209 0 0 1 -1-1 1.00209 1.00209 0 0 1 1-1h4a1.00209 1.00209 0 0 1 1 1 1.00209 1.00209 0 0 1 -1 1zm6-12-17 4 .4 2 17-4z" /></svg>);}
);

PackageIcon.propTypes = {
  className: PropTypes.string,
  theme: PropTypes.oneOf(["light", "dark"]),
  size: PropTypes.oneOf(["xs", "s", "m", "l"])
};

PackageIcon.defaultProps = {
  size: "m"
};

export default PackageIcon;
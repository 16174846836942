import { fetch } from 'whatwg-fetch';
import { PromiseCache, PromisesCache } from '@webteam/promise-cache';

import { TouchWatcher } from './touch-watcher';

/**
 * @typedef {Object} CacheWithWatcher
 * @property {string} name
 * @property {PromiseCache|PromisesCache} cache
 * @property {TouchWatcher} touchWatcher
 */

const displayCacheOverridingWarning = cachePart => {
  console.warn(
    `[GeoService]: You try to change ${cachePart} cache instance after it was used.` +
      `\n  It can make unexpected effects.` +
      `\n  We'll reconfigure cache, but could you please refactor you code for configuring the cache before first call of ${cachePart}`
  );
};

/**
 * @param {string} cacheName
 * @param CacheConstructor
 * @return {CacheWithWatcher}
 */
const createCacheWithTouchControl = (
  cacheName,
  CacheConstructor = PromiseCache
) => {
  const cache = new CacheConstructor();
  return {
    name: cacheName,
    cache,
    touchWatcher: new TouchWatcher(cache)
  };
};

/**
 * @param {CacheWithWatcher} cacheWithTouchWatcher
 * @param {import('@webteam/promise-cache').IPromiseCache|PromisesCache} newCache
 */
const reconfigureCache = (cacheWithTouchWatcher, newCache) => {
  if (cacheWithTouchWatcher.touchWatcher.wasTouched) {
    displayCacheOverridingWarning(cacheWithTouchWatcher.name);
  }
  cacheWithTouchWatcher.touchWatcher.restore();
  cacheWithTouchWatcher.cache = newCache;
  cacheWithTouchWatcher.touchWatcher = new TouchWatcher(newCache);
};

export default class GeoService {
  /**
   * @param {Object} urls
   * @param {string} urls.geo
   * @param {string} urls.countries
   */
  constructor(urls) {
    this.urls = urls;

    this._countryDetection = createCacheWithTouchControl('country detection');
    this._countryCodeDetection = createCacheWithTouchControl('country code');
    this._countries = createCacheWithTouchControl('countries');
    this._localizedCountries = createCacheWithTouchControl(
      'localized countries',
      PromisesCache
    );
  }

  /**
   * @description Ability for reconfiguration caches
   * @param {PromiseCache} countryDetection
   * @param {PromiseCache} countryCodeDetection
   * @param {PromiseCache} countriesCache
   * @param {PromisesCache} localizedCountriesCache
   */
  configureCaches({
    countryDetection,
    countryCodeDetection,
    countriesCache,
    localizedCountriesCache
  }) {
    if (countryDetection) {
      reconfigureCache(this._countryDetection, countryDetection);
    }
    if (countriesCache) {
      reconfigureCache(this._countries, countriesCache);
    }
    if (localizedCountriesCache) {
      reconfigureCache(this._localizedCountries, localizedCountriesCache);
    }
    if (countryCodeDetection) {
      reconfigureCache(this._countryCodeDetection, countryCodeDetection);
    }
  }

  /**
   * @return {Promise<DetectedCountry>}
   */
  detectCountry() {
    return this._countryDetection.cache.run(() =>
      fetch(this.urls.geo).then(res => res.json())
    );
  }

  /**
   * @return {Promise<string>}
   */
  detectCountryCode() {
    // use own cache for giving ability configure
    // and connect this cache with user's stores
    return this._countryCodeDetection.cache.run(() =>
      this.detectCountry().then(country => country.code)
    );
  }

  /**
   * @param {{ [locale]: string }} [options]
   * @return {Promise<Country[]>}
   */
  getCountries({ locale } = {}) {
    if (!locale) {
      return this._countries.cache.run(() =>
        fetch(this.urls.countries)
          .then(res => res.json())
          .then(res => res.map(this._convertToCountry))
      );
    } else {
      return this._localizedCountries.cache
        .run(locale, () =>
          fetch(`${this.urls.countries}?locale=${locale}`)
            .then(res => res.json())
            .then(res => res.map(this._convertToCountry))
        )
        .then(countriesListWithLocale => {
          const countries = countriesListWithLocale.map(
            ({ localName, ...countryWOLocalName }) => ({
              ...countryWOLocalName,
              localName: countryWOLocalName.name
            })
          );
          this._countries.cache.set(countries);
          return countriesListWithLocale;
        });
    }
  }

  /**
   * @return void
   */
  clearCache() {
    this._countries.cache.reset();
    this._countries.touchWatcher.reset();
  }

  /**
   * @param {string} code
   * @param {{ [locale]: string }} [options]
   * @return {Promise<Country>}
   */
  getCountryByCode(code, { locale } = {}) {
    return this.getCountries({ locale }).then(
      countries => countries.filter(c => c.code === code)[0]
    );
  }

  /**
   * @private
   * @return {Promise<Country>}
   */
  _convertToCountry(serverCountry) {
    const { country, currency } = serverCountry;
    const {
      allow_personal_quotes: allowPersonalQuotes,
      iso: code,
      printableName: name,
      region: regionCode,
      salesRegion: salesRegionCode,
      localName
    } = country;

    return {
      allowPersonalQuotes,
      code,
      name,
      localName,
      regionCode,
      salesRegionCode,
      currency: {
        code: currency.iso,
        symbolHasPrefix: currency.prefixSymbol,
        symbol: currency.symbol
      }
    };
  }
}

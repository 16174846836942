export const BREAKPOINTS_VALUES = Object.freeze({
  xlg: 1540,
  lg: 1276,
  md: 1000,
  sm: 640
});

export const BREAKPOINTS = {
  xlg: `screen and (max-width: ${BREAKPOINTS_VALUES.xlg}px)`,
  lg: `screen and (max-width: ${BREAKPOINTS_VALUES.lg}px)`,
  md: `screen and (max-width: ${BREAKPOINTS_VALUES.md}px)`,
  sm: `screen and (max-width: ${BREAKPOINTS_VALUES.sm}px)`
};
export const CUSTOM_MEDIA = {
  '--xlg': `screen and (max-width: ${BREAKPOINTS_VALUES.xlg}px)`,
  '--lg': `screen and (max-width: ${BREAKPOINTS_VALUES.lg}px)`,
  '--md': `screen and (max-width: ${BREAKPOINTS_VALUES.md}px)`,
  '--sm': `screen and (max-width: ${BREAKPOINTS_VALUES.sm}px)`
};

export const COUNTRIES =
  'https://account.jetbrains.com/services/countries.json';

export const COUNTRIES_DEV =
  'https://active.jetprofile-stgn.intellij.net/services/countries.json';

export const GEO = 'https://data.services.jetbrains.com/geo';

export const PRIVACY_CONSENT_GET_MESSAGE =
  'https://account.jetbrains.com/services/consentOptions';

export const PRIVACY_CONSENT_GET_MESSAGE_DEV =
  'https://active.jetprofile-stgn.intellij.net/services/consentOptions';

export const PRIVACY_CONSENT_SEND_ACCEPTANCE =
  'https://account.jetbrains.com/services/acceptAgreement';

export const PRIVACY_CONSENT_SEND_ACCEPTANCE_DEV =
  'https://active.jetprofile-stgn.intellij.net/services/acceptAgreement';

export const PRIVACY_CONSENT_SEND_ACCEPTANCE_NEW =
  'https://forms-service.jetbrains.com/jetprofile';

export const PRODUCTS = 'https://data.services.jetbrains.com/products';

export const PRODUCTS_DEV =
  'https://data-srv.staging.w3jbcom.aws.intellij.net/products';

export const PRODUCTS_RELEASES =
  'https://data.services.jetbrains.com/products/releases';

export const PRODUCTS_RELEASES_DEV =
  'https://data-srv.staging.w3jbcom.aws.intellij.net/products/releases';

export const TIME = 'https://data.services.jetbrains.com/time';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import RadioContext from './radio-group-context';

let idCounter = 0;

export class RadioButtonManager extends Component {
  static propTypes = {
    /** Any type of content */
    children: PropTypes.node,
    /** Initial value for uncontrolled mode */
    defaultValue: PropTypes.string,
    /** Disabled state */
    disabled: PropTypes.bool,
    /** Error state */
    error: PropTypes.bool,
    /** Change value callback. `value => ...` */
    onChange: PropTypes.func,
    /** Appearance theme */
    theme: PropTypes.oneOf(['light', 'dark']),
    /** Set value to control state of the component. `onChange` callback is required. */
    value: PropTypes.string,
    size: PropTypes.oneOf(['m', 's', 'xs']),
    /** @ignore */
    name: PropTypes.string
  };

  static defaultProps = {
    disabled: false,
    error: false,
    size: 'm'
  };

  constructor(props) {
    super(props);

    this.state = {
      value: props.defaultValue
    };
    this.name = `radio-group-${idCounter++}`;
  }

  static getDerivedStateFromProps(props) {
    return {
      isUncontrolled: typeof props.value === 'undefined'
    };
  }

  static onChangeType = 'value';

  handleSetValue = value => {
    const { onChange, name } = this.props;
    this.setState({ value });
    if (onChange) {
      onChange(value, name);
    }
  };

  get value() {
    return this.state.value;
  }

  render() {
    const { error, theme, size, disabled } = this.props;
    return (
      <RadioContext.Provider
        value={{
          error,
          theme,
          size,
          disabled,
          name: this.name,
          setValue: this.handleSetValue,
          value: this.state.isUncontrolled ? this.state.value : this.props.value
        }}
      >
        {this.props.children}
      </RadioContext.Provider>
    );
  }
}

export default RadioButtonManager;

import React from 'react';

import { GroupContext } from './group-context';

import CheckboxControlInner from './checkbox-control-inner';

const CheckboxControl = React.forwardRef(function CheckboxControl(props, ref) {
  return (
    <GroupContext.Consumer>
      {({
        inGroup,
        handleCheckboxChange,
        value: groupValue,
        error,
        theme,
        size,
        disabled
      }) => {
        if (!inGroup) {
          return <CheckboxControlInner ref={ref} {...props} />;
        }
        const {
          value,
          defaultChecked,
          onChange,
          checked,
          error: propsError,
          disabled: propsDisabled,
          theme: propsTheme,
          size: propsSize,
          ...restProps
        } = props;

        return (
          <CheckboxControlInner
            error={error || propsError}
            value={value}
            onChange={handleCheckboxChange}
            checked={groupValue.includes(value)}
            disabled={disabled || propsDisabled}
            theme={theme || propsTheme}
            size={size || propsSize}
            ref={ref}
            {...restProps}
          />
        );
      }}
    </GroupContext.Consumer>
  );
});

CheckboxControl.propTypes = CheckboxControlInner.propTypes;

export default CheckboxControl;

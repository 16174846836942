import React from 'react';
import cn from 'classnames';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import './form-error.pcss';

const FormError = ({
  textClass = 'wt-text-2',
  redText = true,
  className,
  children,
  ...restProps
}) => {
  const { status } = useFormikContext();
  if (!(status && status.error)) {
    return <div {...restProps} className={className} />;
  }
  return (
    <div
      {...restProps}
      className={cn(
        'wt-formik-error',
        redText && 'wt-formik-error_red',
        textClass,
        className
      )}
    >
      {status.error}
    </div>
  );
};

FormError.propTypes = {
  textClass: PropTypes.string,
  redText: PropTypes.bool
};

export default FormError;

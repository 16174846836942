import PropTypes from 'prop-types';

export const optionType = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.string
});
export const optionGroupType = PropTypes.shape({
  label: PropTypes.string,
  options: PropTypes.arrayOf(optionType)
});
export const noOptionsMessageConfigType = PropTypes.shape({
  noOptions: PropTypes.string.isRequired,
  nothingFound: PropTypes.string,
  loading: PropTypes.string
});
export const noOptionsMessageType = PropTypes.oneOf([
  PropTypes.string,
  noOptionsMessageConfigType
]);

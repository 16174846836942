import React, { useState } from 'react';
import PropTypes from 'prop-types';
import bemCnFast from '@webteam/bem-cn-fast';
import { withTheme } from '@webteam/ui-contexts';

import {
  optionType,
  optionGroupType,
  noOptionsMessageType
} from '../common-prop-types';

import SelectTrigger from './trigger';
import FormikTriggerWrapper from './formik-trigger-wrapper';

import localNames from './native-select.pcss';

const bemCn = bemCnFast('wt-native-select', localNames);

const DEFAULT_NO_OPTIONS_MESSAGES = {
  noOptions: 'No options',
  loading: 'Loading...'
};

// eslint-disable-next-line complexity
function NativeSelect({
  options = [],
  size = 'm',
  theme,
  className = null,
  style,
  defaultValue = { label: '' },
  value = undefined,
  onChange,
  placeholder = 'Placeholder',
  isLoading = false,
  isDisabled = false,
  trigger,
  label,
  note,
  error,
  noOptionsMessage,
  'data-test': dataTest
}) {
  const [selected, setSelected] = useState(defaultValue);
  const displayed = value ? value : selected;
  const [hovered, setHovered] = useState(false);

  function handleChange(event) {
    const newValue = event.target.value;
    const selectedOption = findSelectedOption(options, newValue);
    setSelected(selectedOption);

    if (onChange) {
      onChange(selectedOption);
    }
  }

  let optionList;
  if (containsGroups(options)) {
    optionList = options.map(renderOptionGroup);
  } else {
    optionList = options.map(renderOption);
  }

  const placeholderOption = {
    value: '__placeholder__',
    label:
      options.length > 0
        ? placeholder
        : getNoOptionsMessage(noOptionsMessage, isLoading),
    disabled: true
  };

  if (placeholder !== null) {
    optionList.unshift(renderOption(placeholderOption));
  }

  let _trigger;
  if (trigger) {
    _trigger = trigger({
      selectedLabel: displayed.label,
      selectedValue: displayed.value,
      placeholder,
      hovered
    });
  } else {
    _trigger = (
      <SelectTrigger
        theme={theme}
        size={size}
        isOpen={false}
        onKeyDown={() => false}
        selected={displayed}
        placeholder={placeholder}
        searchInput=""
        isClearable={false}
        isLoading={isLoading}
        isDisabled={isDisabled}
        error={Boolean(error)}
      />
    );
  }

  return (
    <FormikTriggerWrapper
      className={className}
      theme={theme}
      size={size}
      error={error}
      label={label}
      note={note}
      data-test={dataTest}
    >
      <div
        className={bemCn()}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        style={style}
        data-test="native-select"
      >
        {_trigger}
        <select
          data-test="native-select_select"
          className={bemCn('select', { size })}
          disabled={isDisabled ? true : undefined}
          onChange={handleChange}
          value={
            displayed.label !== '' ? displayed.value : placeholderOption.value
          }
        >
          {optionList}
        </select>
      </div>
    </FormikTriggerWrapper>
  );
}

NativeSelect.propTypes = {
  className: PropTypes.string,
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(optionType),
    PropTypes.arrayOf(optionGroupType)
  ]),
  defaultValue: optionType,
  value: optionType,
  onChange: PropTypes.func,
  size: PropTypes.oneOf(['m', 's', 'xs']),
  theme: PropTypes.oneOf(['light', 'dark']),
  placeholder: PropTypes.string,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  trigger: PropTypes.func,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.node]),
  label: PropTypes.node,
  note: PropTypes.node,
  noOptionsMessage: noOptionsMessageType,
  style: PropTypes.object,
  'data-test': PropTypes.string
};

function getOptionValue(option) {
  return typeof option.value === 'string' ? option.value : option.label;
}

function isString(s) {
  return Object.prototype.toString.call(s) === '[object String]';
}

function containsGroups(opts) {
  return opts.length > 0 && opts[0].options && Array.isArray(opts[0].options);
}

// eslint-disable-next-line react/prop-types
function renderOptionGroup({ options = [], label = '' }, index) {
  return (
    <optgroup key={index} label={label}>
      {options.map(renderOption)}
    </optgroup>
  );
}

function renderOption(option) {
  const optionProps = {
    key: getOptionValue(option),
    value: getOptionValue(option)
  };

  if (option.disabled) {
    optionProps.disabled = true;
  }

  return (
    <option key={getOptionValue(option)} {...optionProps}>
      {option.label}
    </option>
  );
}

function findSelectedOption(options, selectedValue) {
  if (containsGroups(options)) {
    let result;
    for (const group of options) {
      result = group.options.find(o => getOptionValue(o) === selectedValue);
      if (result) return result;
    }
    return null;
  } else {
    return options.find(o => getOptionValue(o) === selectedValue) || null;
  }
}

function getNoOptionsMessage(noOptionsMessage, isLoading) {
  const { noOptions, loading } = isString(noOptionsMessage)
    ? { ...DEFAULT_NO_OPTIONS_MESSAGES, noOptions: noOptionsMessage }
    : { ...DEFAULT_NO_OPTIONS_MESSAGES, ...noOptionsMessage };

  if (isLoading) {
    return loading;
  } else {
    return noOptions;
  }
}

export default withTheme(NativeSelect);

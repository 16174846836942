import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import bemCnFast from 'bem-cn-fast';
import { useTheme, withTheme } from '@webteam/ui-contexts';
import { privacy } from '@webteam/data-services';
import Tooltip from '@webteam/tooltip';
import InfoIcon from '@webteam/icons/lib/info';

import './privacy-notice.pcss';

const bemCn = bemCnFast('wt-privacy-notice');

const PrivacyNotice = ({
  className,
  consentId,
  countryCode,
  locale,
  size = 'm',
  theme: themeFromProps,
  preferShortText = false,
  defaultMessage = (
    <>
      By submitting this form I agree to the{' '}
      <a
        target="_blank"
        rel="noreferrer noopener"
        href="https://www.jetbrains.com/company/privacy.html"
      >
        JetBrains Privacy Policy
      </a>
    </>
  )
}) => {
  const [textHtml, setTextHtml] = useState();
  const [shortTextHtml, setShortTextHtml] = useState();
  const themeFromProvider = useTheme();
  const theme = themeFromProps || themeFromProvider;

  useEffect(() => {
    if (consentId) {
      privacy
        .getConsentOptions(consentId, { country: countryCode, locale })
        .then(({ text, shortText }) => {
          setShortTextHtml(shortText);
          setTextHtml(text);
        });
    }
  }, [consentId, countryCode, locale]);

  let mainContent = (
    <span className={bemCn('message', { long: true })}>{defaultMessage}</span>
  );
  let tooltipContent = null;
  if (preferShortText && shortTextHtml) {
    mainContent = (
      <span
        className={bemCn('message')}
        dangerouslySetInnerHTML={{ __html: shortTextHtml }}
      />
    );
    tooltipContent = (
      <span
        className={bemCn('tooltip-content')}
        dangerouslySetInnerHTML={{ __html: textHtml }}
      />
    );
  } else if (textHtml) {
    mainContent = (
      <span
        className={bemCn('message', { long: true })}
        dangerouslySetInnerHTML={{ __html: textHtml }}
      />
    );
  }
  return (
    <span className={cn(bemCn({ theme, size }), className)}>
      {mainContent}
      {tooltipContent && (
        <Tooltip size={'s'} placement="right" content={tooltipContent}>
          <InfoIcon className={bemCn('info-icon')} size={size} theme={theme} />
        </Tooltip>
      )}
    </span>
  );
};

PrivacyNotice.propTypes = {
  /** Country code for consent message */
  countryCode: PropTypes.string,
  /** @see `import { privacy } from '@webteam/data-services'; privacy.ConsentId` enum */
  consentId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  /** use `shortText` from privacy consent service, if it's exists, and show full text inside tooltip */
  preferShortText: PropTypes.bool,
  /** message that will be shown during loading text from privacy consent service, or if loading text failed, or if no `consentId` is not provided*/
  defaultMessage: PropTypes.node,
  /** Consent message locale, eg. ru-ru, en-us, ja-jp, etc. */
  locale: PropTypes.string,
  size: PropTypes.oneOf(['xs', 's', 'm']),
  theme: PropTypes.oneOf(['light', 'dark']),
  className: PropTypes.string
};

export default withTheme(PrivacyNotice);


import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import bemCnFast from 'bem-cn-fast';

import './index.pcss';

const bemCn = bemCnFast('wt-icon');

export const Size = {
  "xs": "xs","s": "s","m": "m","l": "l"
};

const CloseIcon = React.forwardRef(
  ({ theme, size, className, ...props }, ref) => {if (size === Size['xs']) { return (<svg viewBox="0 0 16 16" {...props} className={cn(bemCn({theme, size}), className)} ref={ref}><path d="M13.78 3.28l-1.06-1.06L8 6.939 3.28 2.22 2.22 3.28 6.939 8 2.22 12.72l1.06 1.06L8 9.061l4.72 4.719 1.06-1.06L9.061 8l4.719-4.72z" /></svg>); }
return (<svg viewBox="0 0 24 24" {...props} className={cn(bemCn({theme, size}), className)} ref={ref}><path d="M19.707 5.707l-1.414-1.414L12 10.586 5.707 4.293 4.293 5.707 10.586 12l-6.293 6.293 1.414 1.414L12 13.414l6.293 6.293 1.414-1.414L13.414 12l6.293-6.293z" /></svg>);}
);

CloseIcon.propTypes = {
  className: PropTypes.string,
  theme: PropTypes.oneOf(["light", "dark"]),
  size: PropTypes.oneOf(["xs", "s", "m", "l"])
};

CloseIcon.defaultProps = {
  size: "m"
};

export default CloseIcon;
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import bemCnFast from '@webteam/bem-cn-fast';
import { withTheme } from '@webteam/ui-contexts';
import CloseIcon from '@webteam/icons/lib/close';

import localNames from './index.pcss';

const bemCn = bemCnFast('wt-tag', localNames);

function isRemovableTag(isRemovable, size) {
  return isRemovable && ['xs', 's'].includes(size);
}

export const Tag = React.forwardRef(function Tag(
  {
    className,
    isRemovable,
    isClickable,
    disabled,
    size = 'm',
    theme,
    htmlTag: HtmlTag = 'span',
    children,
    onRemoveClick,
    ...restProps
  },
  ref
) {
  const removable = isRemovableTag(isRemovable, size);
  return (
    <HtmlTag
      {...restProps}
      disabled={disabled}
      className={cn(
        bemCn({
          size,
          theme,
          disabled,
          removable,
          clickable: HtmlTag === 'a' || !!restProps.onClick || isClickable
        }),
        className
      )}
      data-test="tag"
      ref={ref}
    >
      {!removable ? (
        children
      ) : (
        <>
          <span className={bemCn('content')}>{children}</span>
          <CloseIcon
            className={bemCn('close')}
            size={size}
            onClick={e => {
              e.stopPropagation();
              if (onRemoveClick) onRemoveClick();
            }}
          />
        </>
      )}
    </HtmlTag>
  );
});

Tag.propTypes = {
  isRemovable: PropTypes.bool,
  isClickable: PropTypes.bool,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['xs', 's', 'm']),
  theme: PropTypes.oneOf(['dark', 'light']),
  htmlTag: PropTypes.string,
  onRemoveClick: PropTypes.func
};

export default withTheme(Tag);


import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import bemCnFast from 'bem-cn-fast';

import './index.pcss';

const bemCn = bemCnFast('wt-icon');

export const Size = {
  "xs": "xs","s": "s","m": "m","l": "l"
};

const TrashIcon = React.forwardRef(
  ({ theme, size, className, ...props }, ref) => {if (size === Size['xs']) { return (<svg viewBox="0 0 16 16" {...props} className={cn(bemCn({theme, size}), className)} ref={ref}><path d="M13 6H4l.894 8.076A1.1 1.1 0 0 0 6.025 15h4.95a1.1 1.1 0 0 0 1.131-.924zm-2.363-4.754L4.496 2.933a1.35 1.35 0 0 0-.944 1.662l.218.788 8.746-2.401-.22-.794a1.35 1.35 0 0 0-1.659-.942z" /></svg>); }
return (<svg viewBox="0 0 24 24" {...props} className={cn(bemCn({theme, size}), className)} ref={ref}><path d="M18.5 9h-12l1.192 10.767A1.466 1.466 0 0 0 9.2 21h6.6a1.466 1.466 0 0 0 1.508-1.233zm-2.986-6.935l-8.19 2.248A1.8 1.8 0 0 0 6.066 6.53l.29 1.05 11.664-3.2-.293-1.058a1.8 1.8 0 0 0-2.212-1.256z" /></svg>);}
);

TrashIcon.propTypes = {
  className: PropTypes.string,
  theme: PropTypes.oneOf(["light", "dark"]),
  size: PropTypes.oneOf(["xs", "s", "m", "l"])
};

TrashIcon.defaultProps = {
  size: "m"
};

export default TrashIcon;
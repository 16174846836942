import React from 'react';
import PropTypes from 'prop-types';
import { useField, useFormikContext } from 'formik';

import { Multiselect } from '@webteam/select';

// eslint-disable-next-line complexity
const FormikMultiselect = ({
  name,
  onChange = () => {},
  disabled,
  options,
  ...restProps
}) => {
  const [{ onChange: och, value, ...formikProps }, meta] = useField(name);
  const {
    setFieldValue,
    setFieldTouched,
    isSubmitting,
    status,
    submitCount
  } = useFormikContext();

  return (
    <Multiselect
      {...restProps}
      {...formikProps}
      options={options}
      value={value}
      error={(meta.touched || submitCount > 0) && meta.error}
      isDisabled={
        isSubmitting ||
        (status && (status.disabled || status.submitted)) ||
        disabled
      }
      onChange={opts => {
        onChange(opts);
        setFieldValue(name, opts);
        if (!meta.touched) {
          setFieldTouched(name, true, false);
        }
      }}
    />
  );
};

FormikMultiselect.propTypes = {
  name: PropTypes.string,
  ...Multiselect.propTypes
};

export default FormikMultiselect;

export const getGroups = state =>
  state.searchString && state.searchString !== ''
    ? state.filteredGroups
    : state.groups;

export const getCursorIndex = state => state.cursorIndex;

export const isIndexMatchCursor = (state, index) => {
  const cursorIndex = getCursorIndex(state);
  if (cursorIndex === null) return false;

  return index[0] === cursorIndex[0] && index[1] === cursorIndex[1];
};

export const isEmpty = state => {
  const groups = getGroups(state);
  return (
    groups.length === 0 ||
    groups.every(g => !g.options || g.options.length === 0)
  );
};

export const withoutGroupsLabel = ({ groups }) => groups.every(g => !g.label);

export const OptionGroupTypes = {
  HEADER: 'header',
  OPTION: 'option',
  DIVIDER: 'divider'
};

export const getPlainOptionsFromGroups = groups =>
  groups.reduce(
    // eslint-disable-next-line no-shadow
    (acc, group, i, groups) =>
      acc.concat(
        [
          optionsNotEmpty(group.options) &&
            group.label && {
              type: OptionGroupTypes.HEADER,
              label: group.label
            },
          ...group.options.map((option, j) => ({
            ...option,
            index: [i, j],
            type: OptionGroupTypes.OPTION
          })),
          optionsNotEmpty(group.options) &&
            i < groups.length - 1 && {
              type: OptionGroupTypes.DIVIDER
            }
        ].filter(e => e)
      ),
    []
  );

export const getPlainOptions = state =>
  getPlainOptionsFromGroups(getGroups(state));

function optionsNotEmpty(options) {
  return options && options.length > 0;
}

export function getOptionValue(option) {
  return typeof option.value === 'string' ? option.value : option.label;
}

export const getSelectedValue = state => {
  if (state.selected === null) {
    return null;
  }

  return getOptionValue(state.selected);
};

export const getSelectedLabel = state => {
  if (state.selected === null) {
    return null;
  }

  return state.selected.label;
};

export const getSelected = state => state.selected;

export const getDefaultCursorIndex = groups => {
  if (groups.length === 0) {
    return null;
  }

  const firstNonEmptyGroupIndex = groups.findIndex(
    group => group.options && group.options.length > 0
  );

  if (firstNonEmptyGroupIndex === -1) {
    return null;
  }

  return [firstNonEmptyGroupIndex, 0];
};


import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import bemCnFast from 'bem-cn-fast';

import './index.pcss';

const bemCn = bemCnFast('wt-icon');

export const Size = {
  "xs": "xs","s": "s","m": "m","l": "l"
};

const SuccessIcon = React.forwardRef(
  ({ theme, size, className, ...props }, ref) => {if (size === Size['xs']) { return (<svg viewBox="0 0 16 16" {...props} className={cn(bemCn({theme, size}), className)} ref={ref}><path d="M14.75 8A6.75 6.75 0 1 1 8 1.25 6.75 6.75 0 0 1 14.75 8zm-2.843-2.096l-1.064-1.058-3.957 3.985L5.19 7.114 4.123 8.167l2.76 2.796z" /></svg>); }
return (<svg viewBox="0 0 24 24" {...props} className={cn(bemCn({theme, size}), className)} ref={ref}><path d="M21 12a9 9 0 1 1-9-9 9 9 0 0 1 9 9zm-3.79-2.795l-1.42-1.41-5.275 5.313-2.261-2.29-1.424 1.405 3.68 3.727z" /></svg>);}
);

SuccessIcon.propTypes = {
  className: PropTypes.string,
  theme: PropTypes.oneOf(["light", "dark"]),
  size: PropTypes.oneOf(["xs", "s", "m", "l"])
};

SuccessIcon.defaultProps = {
  size: "m"
};

export default SuccessIcon;
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import ListContextInner, { DEFAULT_LIST_CONTEXT } from './list-context-inner';

export const ListContext = ({
  size,
  theme,
  mode,
  disabled,
  iconPlacement,
  itemTag,
  children,
  onItemClick = () => {}
}) => {
  const contextValue = useMemo(
    () => ({
      iconPlacement,
      disabled,
      size,
      mode,
      theme,
      itemTag,
      onItemClick
    }),
    [iconPlacement, disabled, size, mode, theme, itemTag, onItemClick]
  );
  return (
    <ListContextInner.Provider value={contextValue}>
      {children}
    </ListContextInner.Provider>
  );
};

ListContext.propTypes = {
  size: PropTypes.oneOf(['l', 'm', 's', 'xs']),
  mode: PropTypes.oneOf(['nude', 'cell']),
  theme: PropTypes.oneOf(['light', 'dark']),
  disabled: PropTypes.bool,
  iconPlacement: PropTypes.oneOf(['left', 'right']),
  itemTag: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
  onItemClick: PropTypes.func
};

ListContext.defaultProps = DEFAULT_LIST_CONTEXT;

export default ListContext;

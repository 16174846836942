
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import bemCnFast from 'bem-cn-fast';

import './index.pcss';

const bemCn = bemCnFast('wt-icon');

export const Size = {
  "xs": "xs","s": "s","m": "m","l": "l"
};

const FilesIcon = React.forwardRef(
  ({ theme, size, className, ...props }, ref) => {if (size === Size['xs']) { return (<svg viewBox="0 0 16 16" {...props} className={cn(bemCn({theme, size}), className)} ref={ref}><path d="m11 1h-5v4h-4v8.5a1.5 1.5 0 0 0 1.5 1.5h5a1.5 1.5 0 0 0 1.5-1.5v-2.5h2.5a1.5 1.5 0 0 0 1.5-1.5v-5.5zm-2.5 12.5h-5v-7h2.5v3a1.5 1.5 0 0 0 1.5 1.5h1zm0-4h-1v-7h2.5v2.5h2.5v4.5z" /></svg>); }
return (<svg viewBox="0 0 24 24" {...props} className={cn(bemCn({theme, size}), className)} ref={ref}><path d="M17 2h-7v5H4v11.957A2.043 2.043 0 0 0 6.043 21h5.964A1.993 1.993 0 0 0 14 19.007V16h3.997A2.003 2.003 0 0 0 20 13.997V5zm-5 17H6V9h4v5a2 2 0 0 0 2 2zm6-5h-6V4h3v3h3z" /></svg>);}
);

FilesIcon.propTypes = {
  className: PropTypes.string,
  theme: PropTypes.oneOf(["light", "dark"]),
  size: PropTypes.oneOf(["xs", "s", "m", "l"])
};

FilesIcon.defaultProps = {
  size: "m"
};

export default FilesIcon;
import {
  cursorMovedUp,
  cursorMovedDown,
  setCursorIndex,
  setGroups,
  setSelectedValue,
  setSelectedIndex,
  clearSelectedAndCloseMenu,
  setSearchString,
  openMenu,
  closeMenu
} from './actions';

import {
  getGroups,
  getCursorIndex,
  isEmpty,
  getDefaultCursorIndex
} from './selectors';

export function getInitialState(groups, defaultSelected = null) {
  return {
    groups,
    menuIsOpen: false,
    selected: defaultSelected,
    searchString: '',
    filteredGroups: null,
    cursorIndex: getDefaultCursorIndex(groups)
  };
}

// eslint-disable-next-line complexity
export function baseReducer(state, action, overrides = {}) {
  switch (action.type) {
    case openMenu.type:
      return { ...state, ...handleOpenMenu(state) };
    case closeMenu.type:
      return { ...state, ...handleCloseMenu(state) };
    case cursorMovedUp.type:
      return { ...state, cursorIndex: up(state, overrides) };
    case cursorMovedDown.type:
      return { ...state, cursorIndex: down(state, overrides) };
    case setCursorIndex.type:
      return { ...state, cursorIndex: action.cursorIndex };
    case setGroups.type:
      return { ...getInitialState(action.groups, state.selected) };
    case setSelectedValue.type:
      return action.selected !== undefined
        ? { ...state, selected: action.selected }
        : { ...state };
    case setSelectedIndex.type:
      return { ...state, ...setSelectedByIndex(state, action.index) };
    case clearSelectedAndCloseMenu.type:
      return { ...state, ...clearSelection(state) };
    case setSearchString.type:
      return {
        ...state,
        ...applySearchFilter(state, action.searchString, action.filterFn)
      };
    default:
      throw new Error('Unexpected action in Select/Multiselect component');
  }
}

export function selectReducer(state, action) {
  switch (action.type) {
    case setSelectedIndex.type:
      return { ...state, ...setSelectedByIndex(state, action.index) };
    default:
      return baseReducer(state, action);
  }
}

function handleOpenMenu(state) {
  return {
    ...applySearchFilter(state, ''),
    menuIsOpen: true
  };
}

function handleCloseMenu(state) {
  return {
    ...applySearchFilter(state, ''),
    menuIsOpen: false
  };
}

function setSelectedByIndex(state, index) {
  const groups = getGroups(state);
  const [grpIndex, optIndex] = index;

  const selectedOption = groups[grpIndex].options[optIndex];

  return {
    selected: selectedOption,
    cursorIndex: getDefaultCursorIndex(groups),
    menuIsOpen: false,
    searchString: ''
  };
}

function clearSelection(state) {
  const groups = getGroups(state);

  return {
    selected: null,
    cursorIndex: getDefaultCursorIndex(groups),
    menuIsOpen: false
  };
}

function down(state, overrides) {
  return moveCursor(state, true, overrides);
}

function up(state, overrides) {
  return moveCursor(state, false, overrides);
}

// eslint-disable-next-line complexity
function moveCursor(state, isDownDirection, overrides) {
  const isGroupsEmpty = overrides.isEmpty
    ? overrides.isEmpty(state)
    : isEmpty(state);

  if (isGroupsEmpty) return getCursorIndex(state);

  const groups = overrides.getGroups
    ? overrides.getGroups(state)
    : getGroups(state);

  const [grpIndex, optIndex] = getCursorIndex(state);
  const optLenByGroup = groups.map(g => (g.options ? g.options.length : 0));

  let newGrpIndex = grpIndex;
  let newOptIndex = nextIndex(optIndex, isDownDirection);

  while (
    isIndexOutOfRange(newOptIndex, optLenByGroup[newGrpIndex]) ||
    isIndexOutOfRange(newGrpIndex, groups.length)
  ) {
    newGrpIndex = nextIndex(newGrpIndex, isDownDirection);

    if (isIndexOutOfRange(newGrpIndex, groups.length)) {
      newGrpIndex = isDownDirection ? 0 : groups.length - 1;
    }

    newOptIndex = isDownDirection ? 0 : optLenByGroup[newGrpIndex] - 1;
  }

  return [newGrpIndex, newOptIndex];
}

function nextIndex(index, isDownDirection) {
  return isDownDirection ? index + 1 : index - 1;
}

function isIndexOutOfRange(index, len) {
  return index < 0 || index >= len;
}

function applySearchFilter(state, searchString, filterFn) {
  if (searchString === '') {
    return {
      ...getInitialState(state.groups, state.selected),
      menuIsOpen: state.menuIsOpen
    };
  } else {
    const filteredGroups = state.groups.map(g => ({
      ...g,
      options: g.options.filter(filterFn)
    }));

    return {
      searchString,
      filteredGroups,
      cursorIndex: getDefaultCursorIndex(filteredGroups),
      menuIsOpen: true
    };
  }
}

import React from 'react';
import { useFormikContext } from 'formik';
import Button from '@webteam/button';
import PropTypes from 'prop-types';

const FormikSubmitButton = ({
  disabled,
  type,
  busy,
  disableWhenFormInvalid = false,
  ...restProps
}) => {
  const { isValid, isSubmitting } = useFormikContext();
  return (
    <Button
      {...restProps}
      disabled={(!isValid && disableWhenFormInvalid) || disabled}
      busy={isSubmitting || busy}
      type={'submit'}
    />
  );
};

FormikSubmitButton.propTypes = {
  ...Button.propTypes,
  disableWhenFormInvalid: PropTypes.bool
};

export default FormikSubmitButton;

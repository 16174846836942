import React, { cloneElement, forwardRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { ValueType } from '../utils';
import { bemCn } from '../tab-list';

import { useTabListContext } from './tab-list-context';

// eslint-disable-next-line react/display-name
const Tab = forwardRef(
  ({ value, className, children, icon, tabId, ['data-e2e']: e2eId }, ref) => {
    const {
      size,
      mode,
      fullText,
      onChange,
      selectedTabId
    } = useTabListContext();

    const short = mode === 'short';
    const selected = tabId === selectedTabId;

    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events
      <div
        className={cn(
          bemCn('tab', {
            size,
            selected,
            short,
            'with-icon': !!icon,
            'full-text': fullText
          }),
          className
        )}
        onClick={() => onChange(value)}
        onKeyDown={changeOnEnter}
        role="tab"
        tabIndex={0}
        ref={ref}
        data-e2e={e2eId}
        data-test={`${selected ? 'tab-selected' : 'tab'}`}
      >
        {icon &&
          cloneElement(icon, {
            className: cn(icon.props.className, bemCn('tab-icon'))
          })}
        <div className={bemCn('ellipsis-text-wrapper')}>{children}</div>
      </div>
    );

    function changeOnEnter(e) {
      if (e.key === 'Enter') {
        onChange(value);
      }
    }
  }
);

Tab.propTypes = {
  className: PropTypes.string,
  /** Additional icon */
  icon: PropTypes.node,
  value: ValueType,
  /**
   * Identifier for e2e tests
   * */
  'data-e2e': PropTypes.string,
  /** @ignore */
  tabId: PropTypes.number
};

export default Tab;

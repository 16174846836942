/**
 * @description Chip version of Proxy for checking any object methods calls in IE11 environment
 */
export class TouchWatcher {
  constructor(object, fields) {
    this._wasTouched = false;
    this._object = object;
    this._originalMethods = new Map();

    this.configureWatching(object, fields);
  }

  get wasTouched() {
    return this._wasTouched;
  }

  restore() {
    if (this._originalMethods.size !== 0) {
      this._originalMethods.forEach((method, methodName) => {
        this._object[methodName] = method;
      });
      this._originalMethods = new Map();
      this._wasTouched = false;
    }
  }

  configureWatching(object, fields) {
    this.restore();

    (fields || Object.keys(object)).forEach(propName => {
      const propValue = object[propName];
      if (typeof propValue === 'function') {
        this._originalMethods.set(propName, propValue);
        object[propName] = function run() {
          this._wasTouched = true;
          // use `function` and `this` here for saving context changing ability
          return propValue.apply(this, arguments);
        };
      }
    });
  }

  reset() {
    this._wasTouched = false;
  }
}

import React from 'react';
import cn from 'classnames';
import Button from '@webteam/button';
import CloseIcon from '@webteam/icons/lib/close';
import PropTypes from 'prop-types';

import { PopupCloseContext, bemCn } from './utils';

export const PopupClose = ({ className, mode = 'nude', ...restProps }) => (
  <PopupCloseContext.Consumer>
    {({ onRequestClose }) => (
      <Button
        {...restProps}
        mode={mode}
        icon={<CloseIcon />}
        className={cn(bemCn('close'), className)}
        onClick={onRequestClose}
        data-test="close-button"
      />
    )}
  </PopupCloseContext.Consumer>
);

PopupClose.propTypes = {
  className: PropTypes.string,
  /** Size */
  size: PropTypes.oneOf(['m', 's', 'xs']),
  /** Appearance mode @see Button.MODE */
  mode: PropTypes.oneOf([
    'primary',
    'contrast',
    'transparent',
    'outline',
    'nude'
  ]),
  /** Appearance theme */
  theme: PropTypes.oneOf(['light', 'dark'])
};

import { createContext } from 'react';

const noop = () => {};

export const GroupContext = createContext({
  value: [],
  addToValue: noop,
  removeFromValue: noop,
  inGroup: false
});


import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import bemCnFast from 'bem-cn-fast';

import './index.pcss';

const bemCn = bemCnFast('wt-icon');

export const Size = {
  "xs": "xs","s": "s","m": "m","l": "l"
};

const MoreIcon = React.forwardRef(
  ({ theme, size, className, ...props }, ref) => {if (size === Size['xs']) { return (<svg viewBox="0 0 16 16" {...props} className={cn(bemCn({theme, size}), className)} ref={ref}><path d="M9.5 14A1.5 1.5 0 1 1 8 12.5 1.5 1.5 0 0 1 9.5 14zM8 6.5A1.5 1.5 0 1 0 9.5 8 1.5 1.5 0 0 0 8 6.5zm0-6A1.5 1.5 0 1 0 9.5 2 1.5 1.5 0 0 0 8 .5z" /></svg>); }
return (<svg viewBox="0 0 24 24" {...props} className={cn(bemCn({theme, size}), className)} ref={ref}><circle cx={12} cy={19} r={2} /><circle cx={12} cy={12} r={2} /><circle cx={12} cy={5} r={2} /></svg>);}
);

MoreIcon.propTypes = {
  className: PropTypes.string,
  theme: PropTypes.oneOf(["light", "dark"]),
  size: PropTypes.oneOf(["xs", "s", "m", "l"])
};

MoreIcon.defaultProps = {
  size: "m"
};

export default MoreIcon;
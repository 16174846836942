import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import bemCnFast from '@webteam/bem-cn-fast';
import { withTheme } from '@webteam/ui-contexts';
import cn from 'classnames';

import AccordionContext from './parts/accordion-context';
import localNames from './index.pcss';

const bemCn = bemCnFast('wt-accordion', localNames);

function Accordion({
  children,
  size = 'm',
  theme,
  heavy = false,
  openItemKey,
  onOpenItemChanged,
  className,
  openClassName
}) {
  const [openKey, setActiveKey] = useState(openItemKey);
  const [switchControlledOff, setSwitchControlledOff] = useState(false);
  useEffect(() => {
    setSwitchControlledOff(!!(!openItemKey && openKey));
    // here we need to run the check only if props.openItemKey has changed
    // it's required to switch sections off in case once
    // controlled accordion receives `undefined` as `openItemKey` prop value
    // and should be ignored on uncontrolled case
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openItemKey]);

  const toggleActiveKey = key => {
    const activatingKey = key !== openKey && key;
    if (onOpenItemChanged) {
      onOpenItemChanged(activatingKey);
    }
    // we're passing 'dummy', so AccordionItems wouldn't reset to defaults of being on without context
    setActiveKey(activatingKey || 'dummy');
  };

  const contextValue = {
    openKey: (switchControlledOff && 'dummy') || openItemKey || openKey,
    toggleActiveKey,
    setActiveKey,
    size,
    theme,
    heavy,
    openClassName
  };
  return (
    <AccordionContext.Provider value={contextValue}>
      <div
        className={cn(bemCn('container'), className)}
        data-test={'accordion'}
      >
        {children}
      </div>
    </AccordionContext.Provider>
  );
}

Accordion.propTypes = {
  children: PropTypes.node,
  size: PropTypes.oneOf(['l', 'm', 's']),
  theme: PropTypes.oneOf(['light', 'dark']),
  heavy: PropTypes.bool,
  className: PropTypes.string,
  openClassName: PropTypes.string,
  /** @ignore */
  openItemKey: PropTypes.string,
  /** @ignore */
  onOpenItemChanged: PropTypes.func
};

export default withTheme(Accordion);

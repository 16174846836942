import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import TextareaAutosize from 'react-textarea-autosize';

import cn from 'classnames';
import bemCnFast from '@webteam/bem-cn-fast';
import { withTheme } from '@webteam/ui-contexts';

import localNames from './index.pcss';

const bemCn = bemCnFast('wt-textarea', localNames);

export class Textarea extends PureComponent {
  static propTypes = {
    id: PropTypes.string,
    /** Set textarea ClassName */
    className: PropTypes.string,
    /**  */
    placeholder: PropTypes.string,
    /** Disable textarea */
    disabled: PropTypes.bool,
    /** Error state flag or message */
    error: PropTypes.oneOfType([PropTypes.bool, PropTypes.node]),
    /** Change value callback. `value => ...` */
    onChange: PropTypes.func,
    /** Focus textarea callback */
    onFocus: PropTypes.func,
    /** Blur textarea callback */
    onBlur: PropTypes.func,
    /** Size */
    size: PropTypes.oneOf(['xs', 's', 'm', 'l']),
    /** Appearance theme */
    theme: PropTypes.oneOf(['light', 'dark']),
    /** Set value to control state of the textarea. `onChange` callback is required. */
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.array
    ]),
    /** Minimum number of rows to show for textarea */
    minRows: PropTypes.number,
    /** Maximum number of rows upto which the textarea can grow */
    maxRows: PropTypes.number,

    label: PropTypes.node,
    note: PropTypes.node
  };

  static defaultProps = {
    theme: 'light',
    size: 'm',
    minRows: 2
  };

  constructor(props) {
    super(props);
    this.state = { empty: !props.value };
  }

  handleTextareaRef = textareaRef => {
    this.textareaRef = textareaRef;
  };

  handleChange = e => {
    const { onChange } = this.props;
    this.setState({ empty: !e.target.value });
    if (onChange) {
      onChange(e);
    }
  };

  focus = () => {
    this.textareaRef.focus();
  };

  // eslint-disable-next-line complexity
  render() {
    const {
      id,
      className,
      disabled,
      error,
      theme,
      size,
      label,
      note,
      ...restProps
    } = this.props;
    const { empty } = this.state;

    let labelTextClass =
      // eslint-disable-next-line no-nested-ternary
      size === 'xs' ? 'wt-text-3' : size === 'l' ? 'wt-text-1' : 'wt-text-2';

    let errorAndNoteTextClass = size === 'l' ? 'wt-text-2' : 'wt-text-3';

    if (theme === 'dark') {
      errorAndNoteTextClass = `${errorAndNoteTextClass} ${errorAndNoteTextClass}_theme_dark`;
      labelTextClass = `${labelTextClass} ${labelTextClass}_theme_dark`;
    }

    return (
      <label
        className={cn(
          bemCn({
            theme,
            size,
            error: !!error,
            empty
          }),
          className
        )}
        id={id}
        data-test={'textarea-label'}
      >
        {label && <div className={labelTextClass}>{label}</div>}
        <div className={bemCn('wrapper')}>
          <TextareaAutosize
            {...restProps}
            className={cn(bemCn('inner'))}
            disabled={disabled}
            onChange={this.handleChange}
            inputRef={this.handleTextareaRef}
            aria-invalid={error}
            data-test={'textarea'}
          />
        </div>
        {note && (
          <div
            className={cn(bemCn('note'), errorAndNoteTextClass)}
            data-test={'textarea-note'}
          >
            {note}
          </div>
        )}
        {error && typeof error !== 'boolean' && (
          <div className={cn(bemCn('error-message'), errorAndNoteTextClass)}>
            {error}
          </div>
        )}
      </label>
    );
  }
}

export default withTheme(Textarea);

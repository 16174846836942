import React from 'react';
import PropTypes from 'prop-types';
import bemCnFast from '@webteam/bem-cn-fast';

import localNames from './unselect-option-hint.pcss';

const bemCn = bemCnFast('wt-unselect-option-hint', localNames);

const UnselectOptionHint = ({ text, size, theme }) => (
  <span className={bemCn({ size, theme })}>{text}</span>
);

UnselectOptionHint.propTypes = {
  text: PropTypes.string,
  size: PropTypes.oneOf(['m', 's', 'xs']),
  theme: PropTypes.oneOf(['light', 'dark'])
};

export default UnselectOptionHint;

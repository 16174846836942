import { fetch } from 'whatwg-fetch';

export default class TimeService {
  constructor(url) {
    this.url = url;
  }

  /**
   * @param {string} timezone
   * @return {Promise<string>}
   */
  getDateByTimezone(timezone) {
    return fetch(`${this.url}?timezone=${timezone}`)
      .then(res => res.json())
      .then(res => res.time);
  }

  /**
   * @return {Promise<string>}
   */
  getUTCDate() {
    return this.getDateByTimezone('UTC');
  }
}

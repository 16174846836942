import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { GroupContext } from './group-context';

class CheckboxManager extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.array,
    defaultValue: PropTypes.array,
    error: PropTypes.bool,
    theme: PropTypes.oneOf(['light', 'dark']),
    size: PropTypes.oneOf(['xs', 's', 'm']),
    disabled: PropTypes.bool
  };

  constructor(props) {
    super(props);
    this.state = {
      value: props.defaultValue || []
    };
  }

  static getDerivedStateFromProps(nextProps) {
    return {
      isControlled: typeof nextProps.value !== 'undefined'
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { onChange } = this.props;
    // call onChange in uncontrolled mode
    if (
      !this.state.isControlled &&
      onChange &&
      prevState.value !== this.state.value
    ) {
      onChange(this.state.value);
    }
  }

  static onChangeType = 'value';

  addToValue = val => {
    const { onChange } = this.props;
    if (!this.state.isControlled) {
      this.setState(prevState => ({
        value: prevState.value.concat(val)
      }));
    } else if (onChange) {
      onChange(this.props.value.concat(val));
    }
  };

  removeFromValue = val => {
    const { onChange } = this.props;
    if (!this.state.isControlled) {
      this.setState(prevState => ({
        value: prevState.value.filter(v => v !== val)
      }));
    } else if (onChange) {
      onChange(this.props.value.filter(v => v !== val));
    }
  };

  handleCheckboxChange = e => {
    if (e.target.checked) {
      this.addToValue(e.target.value);
    } else {
      this.removeFromValue(e.target.value);
    }
  };

  render() {
    const { state, props, handleCheckboxChange } = this;
    const { isControlled } = state;
    const { error, theme, disabled, size } = props;
    const { value } = isControlled ? props : state;
    return (
      <GroupContext.Provider
        value={{
          value,
          handleCheckboxChange,
          error,
          theme,
          disabled,
          size,
          inGroup: true
        }}
      >
        {props.children}
      </GroupContext.Provider>
    );
  }
}

export default CheckboxManager;

import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { withTheme } from '@webteam/ui-contexts';

import RadioContext from './radio-group-context';
import { bemCn } from './bem-cn';

export const RadioButtonControl = React.forwardRef(function RadioButtonControl(
  props,
  ref
) {
  const {
    children,
    disabled: disabledFromProps,
    // eslint-disable-next-line react/prop-types
    onChange,
    value: ownValue,
    checked,
    className,
    theme: propsTheme,
    notUseLabelTag = false,
    ...restProps
  } = props;
  const RootTag = notUseLabelTag ? 'span' : 'label';

  /* eslint-disable react/jsx-no-bind */
  return (
    <RadioContext.Consumer>
      {({
        value: groupValue,
        theme,
        setValue,
        name,
        error,
        size,
        disabled
      }) => (
        <RootTag
          className={cn(
            bemCn({
              size,
              theme: theme || propsTheme,
              error,
              disabled: disabled || disabledFromProps
            }),
            className
          )}
          data-test={'radio-button-control-root'}
        >
          <input
            className={bemCn('input')}
            type="radio"
            onChange={() => setValue(ownValue)}
            disabled={disabled || disabledFromProps}
            value={ownValue}
            name={name}
            checked={groupValue === ownValue}
            ref={ref}
            data-test={'radio-button-control'}
            {...restProps}
          />
          <span className={bemCn('icon', { error })} />
        </RootTag>
      )}
    </RadioContext.Consumer>
  );
});

RadioButtonControl.propTypes = {
  /** Checked state. `onChange` callback is required. */
  checked: PropTypes.bool,
  /** A radio group is defined by giving each of radio buttons in the group the same name */
  theme: PropTypes.oneOf(['light', 'dark']),
  /** Disabled state */
  disabled: PropTypes.bool,
  /** Value used to identify which radio button in a group is selected. The value is never shown to the user by their user agent. */
  value: PropTypes.string.isRequired,
  /** Cancel the use of the `label` tag inside. Set it to `true` only if the component is wrapped in a `label` on the outside. */
  notUseLabelTag: PropTypes.bool
};

export default withTheme(RadioButtonControl);

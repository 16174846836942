addSelectedIndex.type = 'ADD_SELECTED_BY_INDEX';

export function addSelectedIndex(index) {
  return {
    type: addSelectedIndex.type,
    index
  };
}

removeSelectedIndex.type = 'REMOVE_SELECTED_BY_INDEX';

export function removeSelectedIndex(index) {
  return {
    type: removeSelectedIndex.type,
    index
  };
}

setHideSelected.type = 'SET_HIDE_SELECTED';

export function setHideSelected(hideSelected) {
  return {
    type: setHideSelected.type,
    hideSelected
  };
}

removeSelectedOption.type = 'REMOVE_SELECTED_OPTION';

export function removeSelectedOption(unselectedOption) {
  return {
    type: removeSelectedOption.type,
    unselectedOption
  };
}

setSelectedValue.type = 'SET_SELECTED_VALUE';

export function setSelectedValue(selected) {
  return {
    type: setSelectedValue.type,
    selected
  };
}

import React from 'react';
import cn from 'classnames';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import './error-message.pcss';

export const useError = errorKey => {
  const { errors, submitCount } = useFormikContext();

  return [errors && errors[errorKey], submitCount];
};

const ErrorMessage = ({
  errorKey,
  textClass = 'wt-text-2',
  className,
  children,
  ...restProps
}) => {
  const [error, submitCount] = useError(errorKey);
  if (!error || submitCount < 1) {
    return <div {...restProps} className={className} />;
  }
  return (
    <div {...restProps} className={cn('wt-formik-error', textClass, className)}>
      {error}
    </div>
  );
};

ErrorMessage.propTypes = {
  /** Error key in formik's errors object */
  errorKey: PropTypes.string.isRequired,
  textClass: PropTypes.string
};

export default ErrorMessage;

import React from 'react';
import PropTypes from 'prop-types';
import { ListItem } from '@webteam/list';

import classnames from 'classnames/bind';

import localNames from './index.pcss';
import CheckboxControl from './parts/checkbox-control';

const cn = classnames.bind(localNames);

export const Checkbox = React.forwardRef(function Checkbox(
  {
    children,
    className,
    disabled,
    mode = 'nude',
    size,
    theme,
    // eslint-disable-next-line react/prop-types
    onMouseEnter,
    // eslint-disable-next-line react/prop-types
    onMouseLeave,
    // eslint-disable-next-line react/prop-types
    onClick,
    error,
    ...restProps
  },
  ref
) {
  return (
    <>
      <ListItem
        tag={'label'}
        mode={mode}
        size={size}
        theme={theme}
        disabled={disabled}
        className={className}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={onClick}
        icon={
          <CheckboxControl
            ref={ref}
            disabled={disabled}
            size={size}
            theme={theme}
            error={Boolean(error)}
            notUseLabelTag
            {...restProps}
          />
        }
      >
        {children}
      </ListItem>
      {error && typeof error !== 'boolean' && (
        <div
          className={cn(
            'wt-checkbox__error-message',
            'wt-checkbox__error-message_single',
            'wt-text-3'
          )}
          data-test="checkbox-error-message"
        >
          {error}
        </div>
      )}
    </>
  );
});

Checkbox.propTypes = {
  /** Checked state. `onChange` callback is required. */
  checked: PropTypes.bool,
  /** Node or text to show near the input */
  children: PropTypes.node,
  /** Is checkbox initially checked */
  defaultChecked: PropTypes.bool,
  /** Input name attribute */
  name: PropTypes.string,
  /** Appearance theme */
  theme: PropTypes.oneOf(['light', 'dark']),
  /** Disabled state */
  disabled: PropTypes.bool,
  /** Error state */
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.node]),
  /** Value of the checkbox */
  value: PropTypes.string,
  /** Change value callback. `value => ...` */
  onChange: PropTypes.func,
  /** Focus input callback */
  onFocus: PropTypes.func,
  /** Blur input callback */
  onBlur: PropTypes.func,
  /** Indeterminate state */
  indeterminate: PropTypes.bool,
  mode: PropTypes.oneOf(['nude', 'cell']),
  size: PropTypes.oneOf(['xs', 's', 'm'])
};

Checkbox.inputType = 'checkbox';

export default Checkbox;

import React from 'react';
import Input from '@webteam/input';
import PropTypes from 'prop-types';
import { useField, useFormikContext } from 'formik';

const FormikInput = ({
  name,
  onBlur = () => {},
  onChange = () => {},
  disabled,
  ...restProps
}) => {
  const [formikProps, meta] = useField(name);
  const { status, isSubmitting, submitCount } = useFormikContext();
  return (
    <Input
      {...restProps}
      {...formikProps}
      onBlur={e => {
        onBlur(e);
        formikProps.onBlur(e);
      }}
      onChange={e => {
        onChange(e);
        formikProps.onChange(e);
      }}
      disabled={
        isSubmitting ||
        (status && (status.disabled || status.submitted)) ||
        disabled
      }
      error={(meta.touched || submitCount > 0) && meta.error}
    />
  );
};

FormikInput.propTypes = {
  name: PropTypes.string,
  ...Input.propTypes
};

export default FormikInput;

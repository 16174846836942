import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import cn from 'classnames';
import {
  LayeringConsumer,
  LayeringProvider,
  ThemeProvider
} from '@webteam/ui-contexts';
import LoadingIcon from '@webteam/icons/lib/loading';
import { RemoveScrollBar } from 'react-remove-scroll-bar';

import { PopupCloseContext, bemCn } from './parts/utils';
import { PopupClose } from './parts/close';

export default function Popup({
  isOpen = true,
  children,
  onRequestClose,
  className,
  shouldCloseOnEsc = true,
  shouldCloseOnOverlayClick = true,
  showOuterCloseButton = false,
  busy = false,
  ariaHideApp = false,
  scrollBarGapMode = 'margin',
  ...restProps
}) {
  const closeContextValue = useMemo(() => ({ onRequestClose }), [
    onRequestClose
  ]);
  return (
    <PopupCloseContext.Provider value={closeContextValue}>
      <LayeringConsumer>
        {({ popupZIndex }) => (
          <LayeringProvider baseLayer="popup">
            <ReactModal
              isOpen={isOpen}
              className={cn(bemCn(), className)}
              overlayClassName={bemCn('overlay')}
              onRequestClose={onRequestClose}
              shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
              shouldCloseOnEsc={shouldCloseOnEsc}
              {...restProps}
              ariaHideApp={ariaHideApp}
              style={{ overlay: { zIndex: popupZIndex } }}
            >
              <ThemeProvider theme={'light'}>
                {showOuterCloseButton && (
                  <PopupClose
                    className={bemCn('close_embedded')}
                    theme={'light'}
                    mode={'contrast'}
                  />
                )}
                {busy ? (
                  <LoadingIcon
                    className={bemCn('loading-icon')}
                    data-test="loading-icon"
                  />
                ) : (
                  children
                )}
                <RemoveScrollBar gapMode={scrollBarGapMode} />
              </ThemeProvider>
            </ReactModal>
          </LayeringProvider>
        )}
      </LayeringConsumer>
    </PopupCloseContext.Provider>
  );
}

Popup.propTypes = {
  /** is modal currentlly open ("controlled component" prop)
   * @deprecated use conditional rendering in parent instead */
  isOpen: PropTypes.bool,
  /** on esc key pressed or close button clicked or click on overlay,
   * actual calls configured by `shouldCloseOnOverlayClick` and `shouldCloseOnEsc` props */
  onRequestClose: PropTypes.func,
  shouldCloseOnEsc: PropTypes.bool,
  shouldCloseOnOverlayClick: PropTypes.bool,
  showOuterCloseButton: PropTypes.bool,
  children: PropTypes.node,
  /** Disabled by default to avoid warning, more info:
   * @see http://reactcommunity.org/react-modal/accessibility/#app-element */
  ariaHideApp: PropTypes.bool,
  busy: PropTypes.bool,
  /** Which property use to preserve the scroll bar "gap" while scroll is blocked
   * @see https://github.com/theKashey/react-remove-scroll-bar */
  scrollBarGapMode: PropTypes.oneOf(['padding', 'margin'])
};

import { fetch } from 'whatwg-fetch';
import { stringify } from 'query-string';

// eslint-disable-next-line import/extensions
import ConsentId from './privacy-consent-ids.json';

export default class PrivacyConsentService {
  /**
   * @param {PrivacyConsentServiceUrls} urls
   */
  constructor(urls) {
    this.urls = urls;
    this.ConsentId = ConsentId;
  }

  /**
   * @param {string} consentId
   * @param {object} requestParams
   * @return {Promise<string>}
   */
  getConsentOptions(consentId, requestParams = {}) {
    let params;
    if (typeof requestParams === 'string') {
      console.warn(
        `getConsentOptions(consentId, countryCode) method signature is deprecated,` +
          ` please use getConsentOptions(consentId, requestParams). For this call it should be "getConsentOptions(${consentId}, { country: ${requestParams} })" instaed of` +
          ` "getConsentOptions(${consentId}, ${requestParams})", same applicable for getShortMessage and getMessage methods witch is using getConsentOptions under the hood.`
      );
      params = { country: requestParams.toUpperCase(), consentId };
    } else {
      params = { ...requestParams, consentId };
    }
    const url = `${this.urls.getMessage}?${stringify(params)}`;

    return fetch(url).then(res => res.json());
  }

  /**
   * @param {string} consentId
   * @param {object} requestParams
   * @return {Promise<string>}
   */
  getMessage(consentId, requestParams) {
    return this.getConsentOptions(consentId, requestParams).then(
      data => data.text
    );
  }

  /**
   * @param {string} consentId
   * @param {object} requestParams
   * @return {Promise<string>}
   */
  getShortMessage(consentId, requestParams) {
    return this.getConsentOptions(consentId, requestParams).then(
      data => data.shortText
    );
  }

  /**
   * @param {string} consentId
   * @param {string} email
   * @param {SendAcceptanceParams} params
   * @return {Promise<Response>}
   */
  sendAcceptance(consentId, email, { useNewEndpoint = false } = {}) {
    const params = { type: consentId, email };
    const url = `${
      useNewEndpoint ? this.urls.newSendAcceptance : this.urls.sendAcceptance
    }?${stringify(params)}`;
    return fetch(url, { method: 'POST' });
  }
}

import React from 'react';
import cn from 'classnames';

import { withTheme } from '@webteam/ui-contexts';

import { bemCn } from '../tab-list';

// eslint-disable-next-line react/prop-types
const TabSeparator = ({ className, theme }) => (
  <div className={cn(bemCn('separator', { theme }), className)} />
);

export default withTheme(TabSeparator);

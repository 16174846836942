import React, { useState, useRef, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import bemCnFast from '@webteam/bem-cn-fast';
import cn from 'classnames';
import useCollapse from 'react-collapsed';
import ErrorIcon from '@webteam/icons/lib/error';

import localNames from '../index.pcss';

import AccordionContext from './accordion-context';

const bemCn = bemCnFast('wt-accordion-item', localNames);

function AccordionItem({
  className,
  title,
  children,
  initiallyOpen,
  itemKey,
  openClassName
}) {
  //using the trick from  https://github.com/Yaska/react-use-id-hook/
  const { current: collapseKey } = useRef(itemKey || getRandId());
  const {
    openKey,
    toggleActiveKey,
    setActiveKey,
    size,
    theme,
    heavy,
    openClassName: contextOpenClassName
  } = useContext(AccordionContext);
  const [isOpen, setOpen] = useState(initiallyOpen);

  const open = openKey ? openKey === collapseKey : isOpen;
  //ignore initiallyOpen key for controlled components
  const defaultOpen = !openKey && initiallyOpen;

  //NB: use mountChildren from useCollapse to check on animation state
  const { getCollapseProps, getToggleProps, mountChildren } = useCollapse({
    isOpen: open,
    defaultOpen,
    expandStyles: {
      transitionDuration: '300ms'
    },
    collapseStyles: {
      transitionDuration: '300ms'
    }
  });

  useEffect(() => {
    if (defaultOpen) {
      setActiveKey(collapseKey);
    }
  }, []);

  const containerClassName = cn(
    bemCn({ size, theme, open: mountChildren, closed: !mountChildren, heavy }),
    cn(className, {
      [openClassName]: mountChildren,
      [contextOpenClassName]: mountChildren
    })
  );

  const triggerClassName = bemCn('trigger');
  const iconClassName = bemCn('icon', { open });
  const titleClassName = bemCn('title');
  //container required for react-collapsed to correctly define element height for animation
  const contentContainerClassName = bemCn('content-container');
  const contentClassName = bemCn('content');
  const onClick = () => {
    if (toggleActiveKey) {
      toggleActiveKey(collapseKey);
    }
    //this state update will be ignored for controlled components
    setOpen(oldOpen => !oldOpen);
  };

  return (
    <div className={containerClassName} data-test={'accordion-item'}>
      <div className={triggerClassName} {...getToggleProps({ onClick })}>
        <div className={iconClassName}>
          <ErrorIcon size={size} />
        </div>
        <div className={titleClassName}>{title}</div>
      </div>
      <div className={contentContainerClassName} {...getCollapseProps()}>
        <div className={contentClassName}>{children}</div>
      </div>
    </div>
  );
}

AccordionItem.propTypes = {
  className: PropTypes.string,
  openClassName: PropTypes.string,
  title: PropTypes.node,
  initiallyOpen: PropTypes.bool,
  /** @ignore */
  itemKey: PropTypes.string,
  children: PropTypes.node
};

function getRandId() {
  return Math.random()
    .toString(36)
    .substring(7);
}

export default AccordionItem;

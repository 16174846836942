import React, {
  useMemo,
  useState,
  useRef,
  useEffect,
  cloneElement,
  useCallback
} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { ResizeObserver as Polyfill } from '@juggle/resize-observer';
import bemCnFast from '@webteam/bem-cn-fast';
import Button from '@webteam/button';
import LeftIcon from '@webteam/icons/lib/left';
import RightIcon from '@webteam/icons/lib/right';
import { withTheme } from '@webteam/ui-contexts';

import { ValueType, deepMap } from './utils';

import Tab from './parts/tab';
import { TabListContext } from './parts/tab-list-context';

import localNames from './index.pcss';

const ResizeObserver =
  (typeof window !== 'undefined' && window.ResizeObserver) || Polyfill;

export const bemCn = bemCnFast('wt-tab-list', localNames);

const ARROW_BUTTON_WIDTH_IN_PX = 45;

const compareTabsDefault = (activeValue, tabValue) => activeValue === tabValue;

const TabList = ({
  size = 'm',
  className,
  mode = 'default',
  theme = 'light',
  fullText = false,
  value,
  onChange,
  children,
  compareValues = compareTabsDefault,
  arrowBackgroundColor,
  ['data-e2e']: e2eId
}) => {
  const activeNode = useRef();

  const [childrenWithValues, selectedTabId] = useMemo(() => {
    let counter = 0;
    let currentSelectedTabId = null;

    const tabs = deepMap(children, child => {
      if (child.type === Tab) {
        const childValue = child.props.value || counter;

        const hasAlreadySelectedTab = currentSelectedTabId === null;
        const isSelectedTab =
          hasAlreadySelectedTab && compareValues(value, childValue);
        if (isSelectedTab) {
          currentSelectedTabId = counter;
        }

        const patchedTab = patchTabIfNeeded(
          child,
          counter,
          childValue,
          isSelectedTab ? activeNode : null
        );

        counter += 1;
        return patchedTab;
      } else {
        return child;
      }
    });

    return [tabs, currentSelectedTabId];
  }, [children, value, compareValues]);

  const [showLeftArrow, showRightArrow, node] = useScrollContainer(
    childrenWithValues
  );

  const prepareDirectionScroller = direction => () => {
    const { current } = node;
    const left = current.clientWidth * (direction === 'right' ? 1 : -1);
    scrollElementBy(current, left);
  };

  const [indicator, setIndicator] = useState({
    left: 0,
    width: 0
  });
  const [isSSR, setIsSSR] = useState(true);

  useEffect(() => {
    if (!activeNode.current || !node.current) {
      return;
    }
    if (isSSR) {
      setIsSSR(false);
      setIndicator({
        ...calculateIndicator(activeNode.current, node.current),
        transitionDuration: '0ms'
      });
      return;
    }
    setIndicator(calculateIndicator(activeNode.current, node.current));
    scrollIntoView(
      activeNode.current,
      node.current,
      showLeftArrow,
      showRightArrow
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, childrenWithValues]);

  const arrowLeftStyles = arrowBackgroundColor
    ? {
        backgroundColor: arrowBackgroundColor,
        boxShadow: `10px 0 10px ${arrowBackgroundColor}`
      }
    : {};
  const arrowRightStyles = arrowBackgroundColor
    ? {
        backgroundColor: arrowBackgroundColor,
        boxShadow: `-10px 0 10px ${arrowBackgroundColor}`
      }
    : {};

  return (
    <TabListContext.Provider
      value={{
        size,
        mode,
        theme,
        fullText,
        onChange,
        selectedTabId
      }}
    >
      <div
        className={cn(bemCn({ size, mode, theme }), className)}
        data-e2e={e2eId}
        data-test={'tab-list'}
      >
        <div
          className={bemCn('left-arrow', { show: showLeftArrow })}
          style={arrowLeftStyles}
        >
          <Button
            mode="nude"
            notFocusable
            onClick={prepareDirectionScroller('left')}
            icon={<LeftIcon />}
            size="s"
            data-e2e={e2eId ? `${e2eId}__left-arrow` : null}
            data-test={'tab-list-arrow-left'}
          />
        </div>
        <div className={bemCn('tabs-container')} ref={node}>
          {childrenWithValues}
          <span style={indicator} className={bemCn('indicator')} />
        </div>
        <div
          className={bemCn('right-arrow', { show: showRightArrow })}
          style={arrowRightStyles}
        >
          <Button
            theme={theme}
            mode="nude"
            notFocusable
            onClick={prepareDirectionScroller('right')}
            icon={<RightIcon />}
            size="s"
            data-e2e={e2eId ? `${e2eId}__right-arrow` : null}
            data-test={'tab-list-arrow-right'}
          />
        </div>
      </div>
    </TabListContext.Provider>
  );
};

TabList.propTypes = {
  size: PropTypes.oneOf(['m', 'l']),
  theme: PropTypes.oneOf(['light', 'dark']),
  className: PropTypes.string,
  mode: PropTypes.oneOf(['default', 'short']),
  /** @ignore */
  fullText: PropTypes.bool,
  value: ValueType,
  onChange: PropTypes.func,
  /** @ignore */
  compareValues: PropTypes.func,
  /** Custom background color for the arrows */
  arrowBackgroundColor: PropTypes.string,
  /**
   * Identifier for e2e tests
   * */
  'data-e2e': PropTypes.string
};

export default withTheme(TabList);

function patchTabIfNeeded(child, tabId, childValue, activeNode) {
  return cloneElement(child, {
    tabId,
    value: childValue,
    ref: activeNode
  });
}

function calculateIndicator(activeNode, parentNode) {
  const childPos = activeNode.getBoundingClientRect();
  const parentPos = parentNode.getBoundingClientRect();

  const left = `${childPos.left - parentPos.left + parentNode.scrollLeft}px`;
  const width = `${childPos.width}px`;

  return { left, width };
}

function scrollIntoView(activeNode, parentNode, showLeftArrow, showRightArrow) {
  const { width: childWidth } = activeNode.getBoundingClientRect();
  const { width: parentWidth } = parentNode.getBoundingClientRect();

  const parentScrollLeft = parentNode.scrollLeft;
  const activeNodeLeft = parentNode.offsetLeft + activeNode.offsetLeft;
  const activeNodeRight = activeNodeLeft + childWidth;

  const leftArrowMargin = showLeftArrow ? ARROW_BUTTON_WIDTH_IN_PX : 0;
  const rightArrowMargin = showRightArrow ? ARROW_BUTTON_WIDTH_IN_PX : 0;

  const leftBorderIsVisible =
    activeNodeLeft >= parentScrollLeft + leftArrowMargin;
  const rightBorderIsVisible =
    activeNodeRight <= parentScrollLeft + parentWidth - rightArrowMargin;

  //don't scroll if the tab is fully visible
  if (leftBorderIsVisible && rightBorderIsVisible) {
    return;
  }

  const shouldScrollLeft = !leftBorderIsVisible;
  const scrollTo = shouldScrollLeft
    ? activeNodeLeft - leftArrowMargin
    : activeNodeRight - parentWidth + rightArrowMargin;

  scrollElementLeft(parentNode, scrollTo);
}

function useScrollContainer(children) {
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);

  /**
   * @type {React.MutableRefObject<HTMLElement|null>}
   */
  const scrollContainerRef = useRef(null);

  const setArrowsVisibility = useCallback(() => {
    const scrollContainer = scrollContainerRef.current;
    if (!scrollContainer) {
      return;
    }
    const shouldShowAnyArrow =
      scrollContainer.offsetWidth < scrollContainer.scrollWidth;

    setShowLeftArrow(shouldShowAnyArrow && scrollContainer.scrollLeft > 0);
    setShowRightArrow(
      shouldShowAnyArrow &&
        scrollContainer.scrollLeft + scrollContainer.offsetWidth <
          scrollContainer.scrollWidth
    );
  }, [scrollContainerRef.current, showLeftArrow, showRightArrow]);

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (!scrollContainer) {
      return null;
    }
    setArrowsVisibility();
    scrollContainer.addEventListener('scroll', setArrowsVisibility, {
      passive: true
    });

    return () =>
      scrollContainer.removeEventListener('scroll', setArrowsVisibility);
  }, [scrollContainerRef.current, children]);

  useResizeObserver(scrollContainerRef, setArrowsVisibility, [children]);

  return [showLeftArrow, showRightArrow, scrollContainerRef];
}

/**
 * @param {React.MutableRefObject<HTMLElement|null>} nodeRef
 * @param {function} onResize
 * @param {*[]} [deps]
 */
function useResizeObserver(nodeRef, onResize, deps) {
  /**
   * Use resize observer for handling scroll container size changes
   * @type {ResizeObserver|null}
   */
  const [resizeObserver, setResizeObserver] = useState(null);

  /**
   * use `useEffect()` for skipping ResizeObserver initialization during SSR
   */
  useEffect(() => {
    const newResizeObserver = new ResizeObserver(() => {
      // `requestAnimationFrame` is a fix for error 'ResizeObserver loop completed with undelivered notifications'.
      // read more https://github.com/WICG/resize-observer/issues/38#issuecomment-422126006
      requestAnimationFrame(() => onResize());
    });
    setResizeObserver(newResizeObserver);
    return () => newResizeObserver.disconnect();
  }, [onResize]);

  useEffect(() => {
    const node = nodeRef.current;
    if (!node || !resizeObserver) {
      return () => {};
    }

    resizeObserver.observe(node);
    return () => {
      resizeObserver.unobserve(node);
    };
  }, [nodeRef.current, resizeObserver].concat(deps));
}

function scrollElementLeft(element, to) {
  if (element.scrollTo) {
    element.scrollTo({ top: 0, left: to, behavior: 'smooth' });
  } else {
    element.scrollLeft = to;
  }
}

function scrollElementBy(element, to) {
  if (element.scrollBy) {
    element.scrollBy({ top: 0, left: to, behavior: 'smooth' });
  } else {
    element.scrollLeft += to;
  }
}

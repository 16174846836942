import React, {
  useState,
  useReducer,
  useEffect,
  useRef,
  useCallback
} from 'react';
import PropTypes from 'prop-types';
import scrollIntoView from 'scroll-into-view-if-needed';

// eslint-disable-next-line import/order
import Dropdown from '@webteam/dropdown';
// eslint-disable-next-line import/order
import { List, ListItem, Divider, GroupHeader } from '@webteam/list';

import { withTheme } from '@webteam/ui-contexts';

import SelectTrigger from './parts/trigger';
import FormikTriggerWrapper from './parts/formik-trigger-wrapper';
import {
  optionType,
  optionGroupType,
  noOptionsMessageType
} from './common-prop-types';

import { getInitialState, selectReducer } from './state/select/reducer';

import {
  cursorMovedUp,
  cursorMovedDown,
  setCursorIndex,
  setGroups,
  setSelectedValue,
  setSelectedIndex,
  clearSelectedAndCloseMenu,
  setSearchString,
  openMenu,
  closeMenu
} from './state/select/actions';

import {
  withoutGroupsLabel,
  getCursorIndex,
  isEmpty,
  getPlainOptions,
  getSelectedValue,
  getOptionValue,
  OptionGroupTypes,
  isIndexMatchCursor,
  getGroups,
  getSelected
} from './state/select/selectors';

import {
  getEmptyMessage,
  shouldOpenSelect,
  escapeRegex,
  convertToOptionGroupsIfNeeded
} from './utils';

import {
  ARROW_UP,
  ARROW_DOWN,
  SPACE,
  ESCAPE,
  ENTER,
  TAB,
  BACKSPACE
} from './key-codes';

// eslint-disable-next-line complexity
function Select({
  options,
  size = 'm',
  theme,
  className = null,
  defaultValue = null,
  value = undefined,
  onChange,
  placeholder = 'Placeholder',
  isClearable = false,
  isLoading = false,
  isDisabled = false,
  isSearchable = false,
  noOptionsMessage,
  placement = 'bottom',
  'data-test': dataTest,
  ...restProps
}) {
  const dropdownRef = useRef(null);
  const highlightedItemRef = React.createRef();

  const [isKeyboardNavigating, setIsKeyboardNavigating] = useState(false);
  const [state, dispatch] = useReducer(
    selectReducer,
    getInitialState(convertToOptionGroupsIfNeeded(options), defaultValue)
  );

  useEffect(() => {
    dispatch(setGroups(convertToOptionGroupsIfNeeded(options)));
  }, [options]);

  useEffect(() => {
    dispatch(setSelectedValue(value));
  }, [value]);

  const selected = value ? value : getSelected(state);
  const isEmptyList = isEmpty(state);

  const listItems = renderOptionList(
    state,
    size,
    highlightedItemRef,
    handleSelectIndex,
    i => {
      if (!isIndexMatchCursor(state, i)) {
        dispatch(setCursorIndex(i));
      }
    },
    size
  );

  const preventHoverIfNeeded = isKeyboardNavigating
    ? { pointerEvents: 'none' }
    : {};

  useEffect(() => {
    if (dropdownRef.current) {
      dropdownRef.current.scheduleUpdate();
    }
  }, [state.searchString]);

  const handleMouseMove = useCallback(() => setIsKeyboardNavigating(false), []);

  useEffect(() => {
    if (isKeyboardNavigating) {
      document.addEventListener('mousemove', handleMouseMove);
    }

    return () => document.removeEventListener('mousemove', handleMouseMove);
  }, [handleMouseMove, isKeyboardNavigating]);

  useEffect(() => {
    if (isKeyboardNavigating && highlightedItemRef.current) {
      let elementToScrollTo = highlightedItemRef.current;

      if (state.cursorIndex[0] === 0 && state.cursorIndex[1] === 0) {
        const groupHeader = highlightedItemRef.current.previousSibling;
        if (groupHeader) {
          elementToScrollTo = groupHeader;
        }
      }

      scrollIntoView(elementToScrollTo, {
        behavior: 'instant',
        scrollMode: 'if-needed',
        block: 'nearest',
        inline: 'nearest'
      });
    }
  }, [highlightedItemRef, state.cursorIndex, isKeyboardNavigating]);

  function handleClose() {
    dispatch(closeMenu());
    setIsKeyboardNavigating(false);
  }

  function handleSelectIndex(index) {
    const selectedOption = getGroups(state)[index[0]].options[index[1]];
    dispatch(setSelectedIndex(index));
    setIsKeyboardNavigating(false);
    if (onChange) {
      onChange(selectedOption);
    }
  }

  // eslint-disable-next-line complexity
  function handleTriggerKeyDown(e) {
    if (e.key === ESCAPE) {
      handleClose();
      e.preventDefault();
    }

    if (isDisabled) {
      return;
    }

    if (state.menuIsOpen) {
      handleKeyNavigation(e);
    } else {
      if (shouldOpenSelect(e.key)) {
        dispatch(openMenu());
        e.preventDefault();
      }

      if (e.key === BACKSPACE && isClearable) {
        handleClearClick();
        e.preventDefault();
      }
    }
  }

  // eslint-disable-next-line complexity
  function handleKeyNavigation(e) {
    if (e.key === ARROW_DOWN || e.key === ARROW_UP) {
      const isDownDirection = e.key === ARROW_DOWN;
      dispatch(isDownDirection ? cursorMovedDown() : cursorMovedUp());
      setIsKeyboardNavigating(true);
      e.preventDefault();
    }

    if (
      e.key === ENTER ||
      e.key === TAB ||
      (!isSearchable && e.key === SPACE)
    ) {
      const cursorIndex = getCursorIndex(state);
      if (cursorIndex !== null) {
        handleSelectIndex(cursorIndex);
      } else {
        handleClose();
      }
      e.preventDefault();
    }
  }

  function handleClick() {
    if (isDisabled) {
      return;
    }

    const action = state.menuIsOpen ? closeMenu() : openMenu();
    dispatch(action);
  }

  function handleSearchInputChange(searchValue) {
    const filter = new RegExp(`(?:^|\\s)${escapeRegex(searchValue)}`, 'i');
    dispatch(setSearchString(searchValue, ({ label }) => filter.test(label)));
  }

  function handleClearClick() {
    dispatch(clearSelectedAndCloseMenu());

    if (onChange) {
      onChange(null);
    }
  }

  return (
    <Dropdown
      size={size}
      theme={theme}
      onRequestClose={handleClose}
      borderHardness={isEmptyList ? 'pale' : 'average'}
      widthEqualsTriggers
      watchWindowResize
      placement={placement}
      ref={dropdownRef}
      trigger={({ ref }) => (
        <FormikTriggerWrapper
          className={className}
          theme={theme}
          size={size}
          error={restProps.error}
          label={restProps.label}
          note={restProps.note}
          data-test={dataTest}
        >
          <SelectTrigger
            theme={theme}
            size={size}
            isOpen={state.menuIsOpen}
            triggerRef={ref}
            onKeyDown={handleTriggerKeyDown}
            selected={selected}
            placeholder={placeholder}
            isClearable={isClearable}
            isLoading={isLoading}
            isDisabled={isDisabled}
            searchInput={state.searchString}
            isSearchable={isSearchable}
            onSearchChange={handleSearchInputChange}
            onClick={handleClick}
            onClearClick={handleClearClick}
            error={Boolean(restProps.error)}
          />
        </FormikTriggerWrapper>
      )}
      isOpen={state.menuIsOpen}
    >
      <List size={size} theme={theme} style={preventHoverIfNeeded}>
        {!isEmptyList ? (
          listItems
        ) : (
          <ListItem disabled>
            {getEmptyMessage(
              isLoading,
              isSearchable && state.searchString.length > 0,
              noOptionsMessage
            )}
          </ListItem>
        )}
      </List>
    </Dropdown>
  );
}

Select.propTypes = {
  className: PropTypes.string,
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(optionType),
    PropTypes.arrayOf(optionGroupType)
  ]),
  defaultValue: optionType,
  value: optionType,
  onChange: PropTypes.func,
  size: PropTypes.oneOf(['m', 's', 'xs']),
  theme: PropTypes.oneOf(['light', 'dark']),
  placeholder: PropTypes.string,
  isClearable: PropTypes.bool,
  isSearchable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.node]),
  label: PropTypes.node,
  note: PropTypes.node,
  noOptionsMessage: noOptionsMessageType,
  placement: PropTypes.oneOf([
    'auto-start',
    'auto',
    'auto-end',
    'top-start',
    'top',
    'top-end',
    'right-start',
    'right',
    'right-end',
    'bottom-end',
    'bottom',
    'bottom-start',
    'left-end',
    'left',
    'left-start'
  ]),
  'data-test': PropTypes.string
};

function renderOptionList(
  state,
  size,
  highlightedItemRef,
  handleSelectIndex,
  handleHighlightIndex
) {
  // eslint-disable-next-line complexity
  return getPlainOptions(state).map((item, i, collection) => {
    const marginInPx = withoutGroupsLabel(state)
      ? 0
      : { m: 12, s: 6, xs: 0 }[size];

    if (item.type === OptionGroupTypes.OPTION) {
      const { index, label } = item;
      const value = getOptionValue(item);
      const isSelected = value === getSelectedValue(state);
      const isHighlighted = isIndexMatchCursor(state, index);
      const lastItem = i === collection.length - 1;

      return (
        <ListItem
          key={`${value}_${index[0]}`}
          selected={isSelected}
          highlighted={isHighlighted}
          ref={isHighlighted ? highlightedItemRef : null}
          onMouseMove={() => handleHighlightIndex(index)}
          onClick={() => handleSelectIndex(index)}
          style={{ marginBottom: lastItem ? `${marginInPx}px` : '' }}
          data-test={`select-list-item${
            isHighlighted ? ' select-list-item-highlighted' : ''
          }`}
        >
          {label}
        </ListItem>
      );
    }

    if (item.type === OptionGroupTypes.DIVIDER) {
      return (
        // eslint-disable-next-line
        <Divider key={`divider${i}`} style={{ marginTop: `${marginInPx}px` }} />
      );
    }

    if (item.type === OptionGroupTypes.HEADER) {
      const { label } = item;
      // eslint-disable-next-line
      return <GroupHeader key={`header${i}`} text={label} />;
    }

    return null;
  });
}

export default withTheme(Select);

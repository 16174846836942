import { createContext } from 'react';
import bemCnFast from '@webteam/bem-cn-fast';

import localNames from '../index.pcss';

const noop = () => {};

export const PopupCloseContext = createContext({ onRequestClose: noop });

export const bemCn = bemCnFast('wt-popup', localNames);

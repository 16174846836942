import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@webteam/checkbox';
import { useField, useFormikContext } from 'formik';
import cn from 'classnames';
import { useTheme, withTheme } from '@webteam/ui-contexts';
import './checkbox.pcss';

// eslint-disable-next-line complexity
const FormikCheckbox = ({
  name,
  onBlur = () => {},
  onChange = () => {},
  disabled,
  size = 'm',
  theme: themeFromProps,
  smallText = false,
  children,
  ...restProps
}) => {
  const [
    { onBlur: onBlurFromFormik, value, ...restFormikProps },
    meta
  ] = useField(name);
  const {
    setFieldValue,
    isSubmitting,
    status,
    submitCount
  } = useFormikContext();
  const themeFromProvider = useTheme();
  const theme = themeFromProps || themeFromProvider;
  return (
    <Checkbox
      {...restProps}
      name={restFormikProps.name}
      theme={theme}
      size={size}
      onBlur={e => {
        onBlur(e);
        onBlurFromFormik(e);
      }}
      onChange={e => {
        onChange(e);
        setFieldValue(name, e.target.checked);
      }}
      checked={value}
      disabled={
        isSubmitting ||
        (status && (status.disabled || status.submitted)) ||
        disabled
      }
      error={(meta.touched || submitCount > 0) && meta.error}
    >
      <div
        className={cn(
          smallText &&
            `wt-text-3 wt-text-3_theme_${theme} wt-checkbox-small-message wt-checkbox-small-message_size_${size}`
        )}
        data-test="formik-checkbox-message"
      >
        {children}
      </div>
    </Checkbox>
  );
};

FormikCheckbox.propTypes = {
  name: PropTypes.string,
  smallText: PropTypes.bool,
  ...Checkbox.propTypes
};

export default withTheme(FormikCheckbox);

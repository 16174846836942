import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@webteam/checkbox';
import { useField, useFormikContext } from 'formik';
import { useTheme, withTheme } from '@webteam/ui-contexts';

import PrivacyNotice from './privacy-notice';

import './privacy-checkbox.pcss';

// eslint-disable-next-line complexity
const FormikPrivacyCheckbox = ({
  name = 'privacyConsent',
  countryCode,
  locale,
  consentId,
  preferShortText = false,
  defaultMessage,
  requiredErrorMessage = 'This field is required.',
  isRequired = true,
  theme: themeFromProps,
  disabled,
  size = 's',
  smallText = true,
  onBlur = () => {},
  onChange = () => {},
  className,
  ...restProps
}) => {
  const themeFromProvider = useTheme();
  const theme = themeFromProps || themeFromProvider;

  const [{ value, onBlur: onBlurFromFormik }, meta] = useField({
    name,
    validate: val => (!val && isRequired ? requiredErrorMessage : null)
  });

  const {
    submitCount,
    setFieldValue,
    isSubmitting,
    status
  } = useFormikContext();

  return (
    <Checkbox
      {...restProps}
      className={className}
      size={size}
      name={name}
      onBlur={e => {
        onBlur(e);
        onBlurFromFormik(e);
      }}
      onChange={e => {
        onChange(e);
        setFieldValue(name, e.target.checked);
      }}
      theme={theme}
      checked={Boolean(value) || false}
      disabled={
        isSubmitting ||
        (status && (status.disabled || status.submitted)) ||
        disabled
      }
      error={(meta.touched || submitCount > 0) && meta.error}
    >
      <PrivacyNotice
        size={smallText ? 'xs' : size}
        preferShortText={preferShortText}
        theme={theme}
        countryCode={countryCode}
        consentId={consentId}
        defaultMessage={defaultMessage}
        locale={locale}
        className={
          smallText && size !== 'xs' ? 'wt-privacy-checkbox__text_small' : ''
        }
      />
    </Checkbox>
  );
};

FormikPrivacyCheckbox.propTypes = {
  /** Formik values object key */
  name: PropTypes.string,
  /** Country code for consent message */
  countryCode: PropTypes.string,
  /** @see `import { privacy } from '@webteam/data-services'; privacy.ConsentId` enum */
  consentId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  /** use `shortText` from privacy consent service, if it's exists, and show full text inside tooltip */
  preferShortText: PropTypes.bool,
  /** message that will be shown during loading text from privacy consent service, or if loading text failed, or if no `consentId` is not provided*/
  defaultMessage: PropTypes.node,
  /** Error message that will be shown if user doesn't check privacy checkbox */
  requiredErrorMessage: PropTypes.string,
  theme: PropTypes.oneOf(['light', 'dark']),
  disabled: PropTypes.bool,
  /** use text-3 instead text-2 for s and m sizes */
  smallText: PropTypes.bool,
  size: PropTypes.oneOf(['xs', 's', 'm']),
  /** Required validation enabled */
  isRequired: PropTypes.bool,
  /** Consent message locale, eg. ru-ru, en-us, ja-jp, etc. */
  locale: PropTypes.string,
  /** Change value callback. `value => ...` */
  onChange: PropTypes.func,
  /** Blur input callback */
  onBlur: PropTypes.func,
  className: PropTypes.string
};

export default withTheme(FormikPrivacyCheckbox);

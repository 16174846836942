
import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import bemCnFast from 'bem-cn-fast';

import './index.pcss';

const bemCn = bemCnFast('wt-icon');

export const Size = {
  "xs": "xs","s": "s","m": "m","l": "l"
};

const RedoIcon = React.forwardRef(
  ({ theme, size, className, ...props }, ref) => {if (size === Size['xs']) { return (<svg viewBox="0 0 16 16" {...props} className={cn(bemCn({theme, size}), className)} ref={ref}><path d="M4.39 9.076a3.09 3.09 0 0 1 3.118-2.701h1.367v3.75l5.25-4.5-5.25-4.5v3.75H7.532a4.605 4.605 0 0 0-4.619 3.908 4.505 4.505 0 0 0 4.462 5.092h4.5v-1.5h-4.5a3.003 3.003 0 0 1-2.985-3.3z" /></svg>); }
return (<svg viewBox="0 0 24 24" {...props} className={cn(bemCn({theme, size}), className)} ref={ref}><path d="M7.02 13.601A4.12 4.12 0 0 1 11.176 10H13v5l7-6-7-6v5h-1.791a6.14 6.14 0 0 0-6.158 5.21A6.007 6.007 0 0 0 11 20h6v-2h-6a4.004 4.004 0 0 1-3.98-4.399z" /></svg>);}
);

RedoIcon.propTypes = {
  className: PropTypes.string,
  theme: PropTypes.oneOf(["light", "dark"]),
  size: PropTypes.oneOf(["xs", "s", "m", "l"])
};

RedoIcon.defaultProps = {
  size: "m"
};

export default RedoIcon;
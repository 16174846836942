import { baseReducer } from '../select/reducer';
import {
  clearSelectedAndCloseMenu,
  setGroups,
  openMenu,
  closeMenu
} from '../select/actions';

import { getOptionValue, getDefaultCursorIndex } from '../select/selectors';

import { getGroups, isEmpty } from './selectors';

import {
  addSelectedIndex,
  removeSelectedIndex,
  removeSelectedOption,
  setHideSelected,
  setSelectedValue
} from './actions';

export function getInitialState(
  groups,
  defaultSelected = null,
  hideSelected = false
) {
  return {
    groups,
    menuIsOpen: false,
    selected: defaultSelected,
    searchString: '',
    filteredGroups: null,
    cursorIndex: getDefaultCursorIndex(groups),
    hideSelected,
    groupsWithoutSelected: groups
  };
}

// eslint-disable-next-line complexity
export function multiselectReducer(state, action) {
  switch (action.type) {
    case openMenu.type:
      return { ...state, ...handleOpenMenu(state) };
    case closeMenu.type:
      return { ...state, ...handleCloseMenu(state) };
    case addSelectedIndex.type:
      return addDefaultCursorPosFromNewState({
        ...state,
        ...addSelectedByIndex(state, action.index)
      });
    case removeSelectedIndex.type:
      return addDefaultCursorPosFromNewState({
        ...state,
        ...removeSelectedByIndex(state, action.index)
      });
    case removeSelectedOption.type:
      return addDefaultCursorPosFromNewState({
        ...state,
        ...removeSelected(state, action.unselectedOption)
      });
    case setHideSelected.type:
      return addDefaultCursorPosFromNewState({
        ...state,
        ...handleToggleHideSelected(state, action.hideSelected)
      });
    case clearSelectedAndCloseMenu.type:
      return {
        ...state,
        ...clearSelection(state),
        groupsWithoutSelected: state.groups
      };
    case setGroups.type:
      return {
        ...getInitialState(action.groups, state.selected, state.hideSelected)
      };
    case setSelectedValue.type:
      return addDefaultCursorPosFromNewState({
        ...state,
        ...setSelectedControllable(state, action.selected)
      });
    default:
      return baseReducer(state, action, { getGroups, isEmpty });
  }
}

function handleOpenMenu(state) {
  return {
    ...state,
    menuIsOpen: true
  };
}

function handleCloseMenu(state) {
  return {
    ...state,
    menuIsOpen: false
  };
}

function clearSelection(state) {
  const groups = getGroups(state);

  return {
    selected: null,
    cursorIndex: getDefaultCursorIndex(groups),
    menuIsOpen: false
  };
}

function addSelectedByIndex(state, index) {
  const groups = getGroups(state);
  const [grpIndex, optIndex] = index;

  const selectedOption = groups[grpIndex].options[optIndex];
  const newSelected = !state.selected
    ? [selectedOption]
    : [...state.selected, selectedOption];

  return {
    selected: newSelected,
    menuIsOpen: false,
    searchString: '',
    groupsWithoutSelected: filterSelected(state.groups, newSelected)
  };
}

function removeSelectedByIndex(state, index) {
  const groups = getGroups(state);
  const [grpIndex, optIndex] = index;
  const unselectedOption = groups[grpIndex].options[optIndex];

  return removeSelected(state, unselectedOption);
}

function removeSelected(state, option) {
  const selectedWithoutOne = state.selected.filter(
    o => getOptionValue(o) !== getOptionValue(option)
  );

  return {
    selected: selectedWithoutOne,
    menuIsOpen: false,
    searchString: '',
    groupsWithoutSelected: filterSelected(state.groups, selectedWithoutOne)
  };
}

function handleToggleHideSelected(state, hideSelected) {
  const result = {
    hideSelected
  };

  if (hideSelected) {
    result.groupsWithoutSelected = filterSelected(state.groups, state.selected);
  }

  return result;
}

function filterSelected(groups, selected) {
  const selectedValues = {};

  if (selected) {
    selected.forEach(s => {
      selectedValues[getOptionValue(s)] = true;
    });
  }

  return groups.map(g => {
    let newOptions = [];

    if (g.options) {
      newOptions = g.options.filter(
        opt => !selectedValues[getOptionValue(opt)]
      );
    }

    return { ...g, options: newOptions };
  });
}

function setSelectedControllable(state, selected) {
  const result = { selected };

  if (state.hideSelected) {
    result.groupsWithoutSelected = filterSelected(state.groups, selected);
  }

  return result;
}

function addDefaultCursorPosFromNewState(state) {
  return {
    ...state,
    cursorIndex: getDefaultCursorIndex(getGroups(state))
  };
}
